import { HStack, Text } from "@chakra-ui/react";
import { LabeledTextField } from "components/LabeledTextField";

// Custom components
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import { MdDescription, MdPerson } from "react-icons/md";
import { MetaDataStatusBadge } from "views/admin/passes/components/MetaDataStatusBadge";
import AddressFormatted, { addressToString } from "components/AddressFormatted";

function PersonInf({ client, ...props }) {
  // Chakra Color Mode
  const kycMeta = client.metadata?.kyc;
  const kycLink =
    kycMeta?.source && kycMeta?.source !== "manual"
      ? `https://cockpit.sumsub.com/checkus#/applicant/${kycMeta.source}/client/basicInfo`
      : undefined;

  return (
    <GroupingContainer
      title="Personal Information"
      style={{ minHeight: 210 }}
      fontSize="sm"
      {...props}
    >
      <LabeledTextField
        title="Email"
        showCopyButton={true}
        copyValue={client.email}
        value={<Text>{client.email}</Text>}
      />

      <HStack alignItems={"flex-start"}>
        <LabeledTextField
          title="Address"
          flexGrow={1}
          showCopyButton={true}
          copyValue={addressToString(
            client && client.address ? client.address : {}
          )}
          value={<AddressFormatted {...client.address} />}
        />

        <LabeledTextField
          title="KYC & Contract"
          value={<HStack alignItems={"flex-start"}>
            <MetaDataStatusBadge
              topicName={"KYC"}
              topicIcon={<MdPerson />}
              metaData={kycMeta}
              linkOut={kycLink}
            />
            <MetaDataStatusBadge
              topicName={"Contract"}
              topicIcon={<MdDescription />}
              metaData={client.metadata?.contracted}
              onClickIcon={undefined}
            />
          </HStack>}
        />
      </HStack>
    </GroupingContainer>
  );
}

export default PersonInf;
