import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";

// Create a Context for the settings
const SettingsContext = createContext();

// Create a custom hook to use the Settings context
export const useSettings = () => {
  return useContext(SettingsContext);
};

// Create the SettingsContextProvider component
export const SettingsContextProvider = ({ children }) => {
  const getSettingsFn = async () => {
    const client = Client(true);
    const { data } = await client.get(`/api/settings/public`);
    return data;
  };

  const {
    data: publicSettings,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["/api/settings/public"],
    queryFn: getSettingsFn,
  });

  const refreshPublicSettings = () => {
    refetch();
  };

  if (isLoading) {
    return <div>Loading settings...</div>;
  }

  if (isError) {
    return <div>Error loading settings</div>;
  }

  return (
    <SettingsContext.Provider value={{ publicSettings, refreshPublicSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
