import {
  Button,
  Flex,
  VStack,
  HStack,
  Divider,
  Heading,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import Card from "components/card/Card.js";
import { FormInput } from "components/FormInput";
import React from "react";

export default function TechnicalSettingsForm({ config, saveConfig }) {
  const {
    handleSubmit,
    formState: { isDirty },
    control,
  } = useForm({ mode: "onBlur", defaultValues: config });
  function onSubmit(data) {
    // TODO we should be able to send only modified fields as below, but needs handling correctly also in the API
    //
    // const modifiedFields = Object.keys(dirtyFields).reduce((acc, key) => {
    //   if (Object.keys(dirtyFields[key]).length) {
    //     acc[key] = {};
    //     Object.keys(dirtyFields[key]).map((key2) => {
    //       acc[key][key2] = data[key][key2];
    //     });
    //   } else {
    //     acc[key] = data[key];
    //   }
    //   return acc;
    // }, {});

    saveConfig(data);
  }
  return (
    <Flex
      mt={4}
      alignItems="start"
      justifyContent="center"
      flexDirection="column"
    >
      <Card>
        <form id="businessForm" onSubmit={handleSubmit(onSubmit)}>
          <HStack align="stretch" wrap="wrap">
            <Box flexGrow={1}>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Sumsub
                </Heading>
                <FormInput
                  id="sumsub.appToken"
                  label="Sumsub appToken"
                  control={control}
                  variant="floating"
                />

                <FormInput
                  id="sumsub.apiKey"
                  label="Sumsub api key"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="sumsub.apiUrl"
                  label="Sumsub api URL"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="sumsub.callbackSecretKey"
                  label="Sumsub api secret key"
                  control={control}
                  variant="floating"
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Google reCAPTCHA
                </Heading>
                <FormInput
                  id="googleReCaptcha.secret"
                  label="Google reCAPTCHA secret"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="googleReCaptcha.minimumScore"
                  label="Google reCAPTCHA secret"
                  control={control}
                  variant="floating"
                />
              </VStack>
            </Box>
            <Box flexGrow={1}>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Kartra
                </Heading>
                <FormInput
                  id="kartra.key"
                  label="key"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="kartra.password"
                  label="password"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="kartra.appId"
                  label="Kartra App ID"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="kartra.apiEndpoint"
                  label="Api endpoint"
                  control={control}
                  variant="floating"
                />
                <FormInput
                  id="kartra.listName"
                  label="List Name"
                  control={control}
                  variant="floating"
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  Sendgrid API
                </Heading>
                <FormInput
                  id="sendgrid.apiKey"
                  label="Sendgrid API key"
                  control={control}
                  variant="floating"
                  rules={{
                    required: "This is required",
                  }}
                />
              </VStack>
              <VStack spacing={4} align="stretch" minW="49%">
                <Heading mt={6} size="sm">
                  WooCommerce webhook
                </Heading>
                <FormInput
                  id="woocommerce.callbackSecretKey"
                  label="Callback Secret Key"
                  control={control}
                  variant="floating"
                />
              </VStack>
            </Box>
          </HStack>
          <Divider mt={4} />
          <Flex justify={"center"}>
            <Button
              type="submit"
              w="100%"
              colorScheme={"green"}
              disabled={!isDirty}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Card>
    </Flex>
  );
}
