import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";
import { useState } from "react";
import ActionButton from "./ActionButton";

export const jsonToCsvTransformer = (json) => {
  const header = Object.keys(json[0]).join(",");
  const rows = json
    .map((obj) => {
      const row = Object.values(obj)
        .map((val) => {
          if (typeof val === "string") {
            return `"${val}"`;
          }
          return val;
        })
        .join(",");
      return row;
    })
    .join("\n");
  return header + "\n" + rows;
};

export const CsvDownloadButton = ({
  url,
  fileName,
  children,
  transformer,
  type,
  ...params
}) => {
  const [downloadTrades, setDownloadTrades] = useState(false);

  const fetchTrades = async () => {
    const data = await Client(true).get(url);
    return data.data;
  };

  const { loading } = useQuery({
    queryKey: [url],
    queryFn: async () => {
      return await fetchTrades(url);
    },
    onSuccess: (data) => {
      setDownloadTrades(false);
      const content = transformer ? transformer(data) : data;

      const csvBlob = new Blob([content], { type });

      // Create a URL for the Blob
      const url = URL.createObjectURL(csvBlob);

      // Create an anchor (<a>) element and trigger download
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = fileName; // Name of the file to be downloaded
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up by revoking the Blob URL and removing the anchor element
      URL.revokeObjectURL(url);
      downloadLink.remove();
    },
    enabled: downloadTrades,
  });

  return (
    <ActionButton
      onClick={() => setDownloadTrades(true)}
      {...params}
      isLoading={loading}
    >
      {children}
    </ActionButton>
  );
};
