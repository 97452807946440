import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdOutlinePaid,
  MdPeople,
  MdVerifiedUser,
  MdAccountBalance,
  MdOutlineSettings,
  MdOutlineMiscellaneousServices,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import Clients from "views/admin/clients";
import Payouts from "views/admin/payouts";
import Passes from "views/admin/passes";
import TradeAccounts from "views/admin/tradeAccounts";
import ClientDetail from "views/admin/clients/components/dashboard/ClientDetail";
import TechnicalSettings from "views/admin/settings/technicalSettings";
import OrganisationSettings from "views/admin/settings/organisationSettings";
import UserManagement from "views/admin/userManagement";
import { CgOrganisation } from "react-icons/cg";

export const getUserRoutes = (user) => {
  if (!user) {
    return [];
  }

  const { role } = user;

  return [
    {
      name: "Main Dashboard",
      layout: "/admin",
      path: "/default",
      icon: <MdHome />,
      component: <MainDashboard />,
      isShow:
        role === "opsManager" || role === "opsAdmin" || role === "masterAdmin",
      enabled:
        role === "opsManager" || role === "opsAdmin" || role === "masterAdmin",
    },
    {
      name: "Account Passes",
      layout: "/admin",
      path: "/passes",
      icon: <MdVerifiedUser />,
      component: <Passes />,
      isShow:
        role === "opsContributor" ||
        role === "opsManager" ||
        role === "opsAdmin" ||
        role === "masterAdmin",
      enabled:
        role === "opsContributor" ||
        role === "opsManager" ||
        role === "opsAdmin" ||
        role === "masterAdmin",
    },
    {
      name: "Payouts",
      layout: "/admin",
      path: "/payouts",
      icon: <MdOutlinePaid />,
      component: <Payouts />,
      isShow: true,
      enabled: true,
    },
    {
      name: "Client",
      layout: "/admin",
      path: "/clients/:id",
      icon: <MdPeople />,
      component: <ClientDetail />,
      isShow: false,
      enabled:
        role === "opsContributor" ||
        role === "opsManager" ||
        role === "opsAdmin" ||
        role === "masterAdmin",
      hasParent: true,
      parentText: "Clients",
      parentUrl: "#/admin/clients",
    },
    {
      name: "Clients",
      layout: "/admin",
      path: "/clients",
      icon: <MdPeople />,
      component: <Clients />,
      isShow:
        role === "opsContributor" ||
        role === "opsManager" ||
        role === "opsAdmin" ||
        role === "masterAdmin",
      enabled:
        role === "opsContributor" ||
        role === "opsManager" ||
        role === "opsAdmin" ||
        role === "masterAdmin",
    },
    {
      name: "Accounts",
      layout: "/admin",
      path: "/accounts",
      icon: (
        <Icon
          as={MdAccountBalance}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),
      component: <TradeAccounts />,
      isShow:
        role === "opsContributor" ||
        role === "opsManager" ||
        role === "opsAdmin" ||
        role === "masterAdmin",
      enabled:
        role === "opsContributor" ||
        role === "opsManager" ||
        role === "opsAdmin" ||
        role === "masterAdmin",
    },

    {
      name: "Settings",
      category: "settings",
      layout: "/admin",
      path: "/settings/organisation",
      isShow: role === "opsAdmin" || role === "masterAdmin",
      enabled: role === "opsAdmin" || role === "masterAdmin",
      icon: (
        <Icon
          as={MdOutlineSettings}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),

      items: [
        {
          name: "Users",
          layout: "/admin",
          path: "/settings/users",
          icon: <MdPeople />,
          component: <UserManagement />,
          isShow: role === "opsAdmin" || role === "masterAdmin",
          enabled: role === "opsAdmin" || role === "masterAdmin",
        },

        {
          name: "Organisation",
          layout: "/admin",
          path: "/settings/organisation",
          component: <OrganisationSettings />,
          icon: (
            <Icon
              as={CgOrganisation}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          isShow: role === "opsAdmin" || role === "masterAdmin",
          enabled: role === "opsAdmin" || role === "masterAdmin",
          hasParent: true,
        },
        {
          name: "Technical",
          layout: "/admin",
          path: "/settings/technical",
          component: <TechnicalSettings />,
          isShow: role === "masterAdmin",
          icon: (
            <Icon
              as={MdOutlineMiscellaneousServices}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          enabled: role === "masterAdmin",
          hasParent: true,
        },
      ],
    },
    {
      type: "filler",
    },
    {
      type: "divider",
    },
    {
      name: user.name,
      subName: user.role,
      layout: "/admin",
      path: "/profile",
      icon: <MdPerson />,
      component: <Profile />,
      isShow: true,
      enabled: true,
    },
  ];
};
