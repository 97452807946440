import React from "react";
import BasicMetricView from "./BasicMetricView";
import CopyComponent from "components/CopyComponent";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import CopyTradingReport from "components/CopyTradingReport";
import { formatMoney } from "variables/utils";
import { HStack, Text, VStack } from "@chakra-ui/react";
import FtmsBreachesTable from "components/ftmsBreachesTable";

const getAccountSize = (row) =>
  row?.account?.plan?.startingBalance
    ? row.account.plan.startingBalance
    : row?.account?.startingBalance;

const getTradeProfit = (row) =>
  row?.account?.report?.copyTradeReports[0]?.profitFromWithSimilarTrades;

const getTotalWinnings = (row) => row?.account?.report?.totalWinnings;

const StatusViewRequestReview = ({ data: row }) => {
  return (
    <VStack width="100%" height="100%">
      <HStack width="100%" alignItems="stretch" flex={1}>
        <GroupingContainer flex={1} title={"Report Overview"}>
          <BasicMetricView
            data={[
              {
                label: "Updated At",
                value: new Date(
                  row?.account?.report?.timeUpdated * 1000
                )?.toLocaleString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }),
              },
              {
                label: "Analysed",
                value:
                  row?.account?.report?.copyTradeReports[0]
                    ?.analyzedTrades,
              },
              {
                label: "Similar",
                value:
                  row?.account?.report?.copyTradeReports[0]
                    ?.countWithSimilarTrades,
              },
              {
                label: "Account Size",
                value: isNaN(getAccountSize(row)) ? undefined : (
                  <CopyComponent
                    component={<Text>{formatMoney(getAccountSize(row))}</Text>}
                    value={`${formatMoney(getAccountSize(row))}`}
                  />
                ),
              },
              {
                label: "Trade Profit",
                value: isNaN(getTradeProfit(row))
                  ? undefined
                  : `${formatMoney(getTradeProfit(row))}`,
              },
              {
                label: "Total Winnings",
                value: isNaN(getTotalWinnings(row))
                  ? undefined
                  : `${formatMoney(getTotalWinnings(row))}`,
              },
            ]}
          />
        </GroupingContainer>
        <GroupingContainer flex={1} title={"Copy Trade Report"}>
          <CopyTradingReport
            data={{
              report: row?.account?.report?.copyTradeReports[0],
              account: row?.account,
            }}
          />
        </GroupingContainer>
      </HStack>

      {row?.account?.ftmsBreaches?.length > 0 && <GroupingContainer flex={1} title={"FTMS Breaches"}>
        <FtmsBreachesTable data={row?.account?.ftmsBreaches} />
      </GroupingContainer>}
    </VStack>
  );
};

export default StatusViewRequestReview;
