import React from "react";
import { NavLink, useMatch } from "react-router-dom";
// chakra imports
import { Box, HStack, VStack, Text, Divider, Badge } from "@chakra-ui/react";
import { useContext } from "react";
import FeatureSwitchContext from "contexts/FeatureSwitchProvider";

export function SidebarLinks(props) {
  const { routes } = props;
  const { featureSwitches } = useContext(FeatureSwitchContext);

  // verifies if routeName is the one active (in browser input)
  const match = useMatch("/admin/settings/*");
  const isActiveSettings = Boolean(match);

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes, depth = 0) => {
    return (
      routes?.map((route, index) => {
        if (route.type === "divider") {
          return <Divider my={5} key={`${depth}_${index}`} />;
        } else if (route.type === "filler") {
          return <Box key={`${depth}_${index}`} flex="1"></Box>;
        } else if (
          route.enabled &&
          route.isShow &&
          (!route.featureSwitch ||
            featureSwitches[route.featureSwitch]?.enabled)
        ) {
          return (
            <Box
              key={index}
              p={0}
              w="100%"
              className={`${isActiveSettings ? "open" : ""} ${depth > 0 ? "sub" : ""}`}
            >
              <NavLink
                to={route.layout + route.path}
                className={({ isActive, isPending }) => {
                  return isActive && !route.items
                    ? "active"
                    : isPending
                      ? "pending"
                      : "";
                }}
              >
                <>
                  <HStack gap={2}>
                    <div className="menu-icon" style={{ minWidth: "25px" }}>
                      {route.icon}
                    </div>
                    <HStack flexGrow={1}>
                      <VStack flexGrow={1} alignItems="beginning" gap={0}>
                        <Text flexGrow={1} marginBlock="0">
                          {route.name}
                        </Text>

                        {route.subName && (
                          <Text
                            margin="0!important"
                            fontSize="sm"
                            color="gray.400"
                          >
                            {route.subName}
                          </Text>
                        )}
                      </VStack>
                      {route.badges && route.badges.length
                        ? route.badges.map((badge, index) => (
                            <Badge
                              key={index}
                              title={badge.title}
                              colorScheme={badge.colorScheme}
                              variant="outline"
                            >
                              {badge.value}
                            </Badge>
                          ))
                        : null}
                    </HStack>
                    <Box
                      className="activeMarker"
                      h="36px"
                      w="4px"
                      borderRadius="5px"
                    />
                  </HStack>
                </>
              </NavLink>
              {createLinks(route.items, depth + 1)}
            </Box>
          );
        } else {
          return null;
        }
      }) || []
    );
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
