export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatMoney = (amount, precision = 2) => {
  try {
    if (typeof amount === "string" && amount.includes(",")) {
      amount = amount.replace(/,/g, "");
    }
    const fmt = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: precision,
    });

    return fmt.format(amount);
  } catch (e) {
    console.error("Error formatting money: ", e);
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const camelCaseToHumanReadable = (actionType) => {
  return (
    actionType
      // Insert a space before each capital letter
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // Capitalize the first letter of each word
      .replace(/\b\w/g, (char) => char.toUpperCase())
  );
};
