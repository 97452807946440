import { useMutation } from "@tanstack/react-query";
import OrganisationSettingsForm from "./components/OrganisationSettingsForm";
import { Box } from "@chakra-ui/react";
import { Client } from "api/axios";
import { toast } from "react-toastify";
import { ALERT_MESSAGE } from "variables/message";
import { useSettings } from "contexts/SettingsProvider";

export default function OrganisationSettings() {
  const settings = useSettings();

  const saveSettingsFn = async (config) => {
    const client = Client(true);
    await client.patch(`/api/settings/public`, config);
  };

  const saveSettings = useMutation({
    mutationFn: saveSettingsFn,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.PASS_ACTION_SUCCESS);

      await settings.refreshPublicSettings();
    },
  });

  const saveConfig = async (config) => {
    saveSettings.mutate(config);
  };

  return (
    <Box>
      {settings?.publicSettings ? (
        <OrganisationSettingsForm saveConfig={saveConfig} config={settings?.publicSettings} />
      ) : null}
    </Box>
  );
}
