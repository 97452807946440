import { HStack, VStack, Text, Badge } from "@chakra-ui/react";
import FtpBadge from "./FtpBadge";
import FpfxAccountLink from "./FpfxAccountLink";

const AccountAbstract = ({ account, showDescription, hideFtmsBreach, showFpfxState }) => {
  const ftmsBreach = account?.ftmsBreaches?.length > 0

  let color = undefined;
  if (!hideFtmsBreach && ftmsBreach) {
    color = 'red';
  }

  return (
    <VStack align='left'>
      <HStack>
        <FtpBadge value={account?.login} title="Account Login" color={color}>
          {account?.login}
        </FtpBadge>
        {account?.accountId && <FtpBadge value={account?.accountId} title="FPFX Account Id" color={color}>
          {account.accountId}
        </FtpBadge>}
        <FpfxAccountLink accountId={account?.accountId} />
      </HStack>
      {(showFpfxState || (!hideFtmsBreach && ftmsBreach)) && <HStack>
        {showFpfxState && account?.stats?.breached && <Badge colorScheme='red'>FPFX Breached</Badge>}
        {showFpfxState && account?.stats?.passed && <Badge colorScheme='green'>FPFX Passed</Badge>}
        {!hideFtmsBreach && ftmsBreach && <Badge color={color}>FTMS Breached</Badge>}
      </HStack>}
      {showDescription && <Text fontSize="xs"
        style={{
          textOverflow: "ellipsis",
          maxWidth: 170,
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}>{account?.description}</Text>}
    </VStack >
  )
};

export default AccountAbstract;