import { Box, Grid } from "@chakra-ui/react";

import PasswordReset from "views/admin/profile/components/PasswordReset";

import React from "react";

export default function Overview() {

  return (
    <Box>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 2fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <PasswordReset
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={25.6}
          total={50}
        />
      </Grid>
    </Box>
  );
}
