// AuthProvider.js

import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";
import { createContext } from "react";
const FeatureSwitchContext = createContext({});
export const FeatureSwitchProvider = ({ children }) => {

  const {  error, data:featureSwitches } = useQuery({
    queryKey: ["featureSwitches"],
    queryFn: () => Client(true).get(`/api/feature-switches`),
    initialData: { data: {} },
  });

  if (error) {
    console.error("featureSwitches error",error);
  }

  return (
    <FeatureSwitchContext.Provider
      value={{featureSwitches:featureSwitches?.data||{}}}
    >
      {children}
    </FeatureSwitchContext.Provider>
  );
};

export default FeatureSwitchContext;
