import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { MdContentCopy, MdCheck } from "react-icons/md";
import { toast } from "react-toastify";

const CopyComponent = ({ component, value, ...props }) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    toast.success("Copied to clipboard");
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  if (!component) {
    component = <Text>{value}</Text>;
  }

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
      {...props}
    >
      {component}
      {value && (
        <button onClick={handleCopy} style={{ marginLeft: 5 }}>
          {isCopied ? <MdCheck /> : <MdContentCopy />}
        </button>
      )}
    </Box>
  );
};

export default CopyComponent;
