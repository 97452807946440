import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { InputGroup } from "@chakra-ui/input";
import {
  FormHelperText,
  FormLabel,
  Input,
  InputLeftElement,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export function FormInput({
  id,
  placeholder,
  helperText,
  control,
  rules,
  type,
  defaultValue,
  icon,
  leftAddon,
  rightAddon,
  disabled,
  label,
  register,
  size,
  variant,
  ...rest
}) {
  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      shouldUnregister={true}
      flexGrow={1}
      render={({
        field: { onChange, onBlur, name, ref, value },
        fieldState: { error },
      }) => (
        <FormControl
          {...rest}
          variant={variant ? variant : "floating"}
          isInvalid={!!error}
          id={id}
          isDisabled={disabled}
        >
          <InputGroup alignItems={"center"}>
            {leftAddon}
            {icon ? (
              <InputLeftElement
                top={0}
                bottom={0}
                pointerEvents="none"
                height="stretch"
              >
                {icon}
              </InputLeftElement>
            ) : null}
            <Input
              placeholder={placeholder}
              onChange={onChange} // send value to hook form
              onBlur={onBlur} // notify when input is touched/blur
              name={name} // send down the input name
              ref={ref}
              size={size}
              type={type}
              value={value} // input value
              {...register}
            />
            {rightAddon}
          </InputGroup>
          {label ? <FormLabel fontSize={14}>{label}</FormLabel> : null}
          {error && (
            <FormErrorMessage>{error && error.message}</FormErrorMessage>
          )}
          {!error && helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
