import { Box, HStack } from "@chakra-ui/react";
import Profile from "views/admin/clients/components/information/Profile";
import PersonInf from "views/admin/clients/components/information/PersonInf";
import { Client } from "api/axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import TradeAccounts from "../information/TradeAccounts";

function ClientDetail() {
  const { id } = useParams();
  const queryClient = useQueryClient();

  async function fetchClientData() {
    const api_url = `/api/client/${id}`;
    return await Client(true).get(api_url);
  }

  const { data: clientObj, isLoading } = useQuery({
    queryKey: [`/api/client/`, id],
    queryFn: fetchClientData,
    keepPreviousData: true,
    initialData: { queryData: { docs: [] } },
  });

  const navigate = useNavigate();
  const toBack = () => {
    navigate("/admin/clients");
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Box>
        <HStack alignItems={"flex-start"} flexWrap={"wrap"} gap={4}>
          <Profile
            client={clientObj.data || {}}
            toBack={toBack}
            isLoading={isLoading}
            w={"40%"}
          />
          <PersonInf flexGrow={1} client={clientObj.data || {}} />
        </HStack>
        <TradeAccounts client={clientObj.data || {}} />
      </Box>
    </QueryClientProvider>
  );
}

export default ClientDetail;
