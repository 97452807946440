import { extendTheme } from "@chakra-ui/react";
import { Card } from "./additions/card/card";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";
import { Form } from "./additions/floatingLabels";
export default extendTheme(
  {
    breakpoints,
    config: {
      initialColorMode: "dark",
      useSystemColorMode: false,
    },
    components: {
      Card,
      Form,
      Modal: {
        baseStyle: {
          dialog: {
            background: "navy.800",
          },
        },
      },
    },
  }, // Breakpoints
  globalStyles // card component
);
