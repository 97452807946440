// Chakra imports
import {
  Button,
  Text,
  InputGroup,
  Heading,
  FormControl,
  FormErrorMessage,
  Select,
  VStack,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { bankTypes } from "../constant";
import { useEffect, useState } from "react";
import BankDetailsForm from "./BankDetailsForm";

export default function RoutingForm(props) {

  const { preStep, nextStep, formData, setFormData } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm(
    { mode: "onBlur", defaultValues: formData, shouldUnregister: true },
    formData
  );

  function onSubmit(values) {
    setFormData({ ...formData, ...values });
    nextStep();
  }

  const [bankInf, setBankInf] = useState(null);
  const bankCountry = watch("bankCountry");

  useEffect(() => {
    if (bankCountry) {
      setBankInf(bankTypes.find((obj) => obj.code === bankCountry));
    } else {
      setBankInf(null);
    }
  }, [bankCountry, setBankInf]);

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Payout Information Form</Heading>
      <Text>
        These details are provided by your bank and can usually be found on
        your monthly statement. If you are unable to locate them, please reach
        out to your bank as we can not help. Ensure that they are accurately
        entered.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} align="stretch">
          <FormControl isInvalid={errors.bankCountry} variant="floating">
            <InputGroup>
              <Select
                id="bankCountry"
                {...register("bankCountry", { required: "This is required" })}
              >
                {bankTypes
                  .sort((a, b) => a.full.localeCompare(b.full))
                  .map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.full} ({country.code})
                    </option>
                  ))}
              </Select>
            </InputGroup>
            <FormLabel>Bank Country</FormLabel>
            <FormErrorMessage>
              {errors.bankCountry && errors.bankCountry.message}
            </FormErrorMessage>
          </FormControl>

          {bankInf ? (
            <BankDetailsForm
              bankInf={bankInf}
              control={control}
              setValue={setValue}
            />
          ) : null}
          <HStack align="stretch" wrap="wrap">
            <Button
              variant="ghost"
              onClick={preStep}
            >
              Back
            </Button>
            <Button
              type="submit"
              colorScheme={"green"}
            >
              Next
            </Button>
          </HStack>
        </VStack>
      </form>
    </VStack>
  );
}
