import { Box, Stack, Text } from "@chakra-ui/react";

export function GroupingContainer({
  children,
  title,
  borderColor = "whiteAlpha.300",
  extraTitle = null,
  containerPadding = 1,
  color = "whiteAlpha.900",
  hideIfEmpty = false,
  direction = "column",
  spacing = 2,
  overflowY = "none",
  ...props
}) {
  if (hideIfEmpty && !children) {
    return null;
  }

  return (
    <Stack
      {...props}
      direction="row"
      border="1px solid"
      borderColor={borderColor}
      borderRadius="7px"
    >
      <Box
        borderBottomLeftRadius="inherit"
        borderTopLeftRadius="inherit"
        backgroundColor={borderColor}
        color={color}
        display="flex"
        flexDir={"column"}
      >
        {extraTitle}

        <Text
          letterSpacing={1}
          p={"2px"}
          flexGrow={1}
          style={{
            writingMode: "vertical-rl",
            transform: "rotate(180deg)",
            textAlign: "end",
            margin: "10px 0",
          }}
        >
          {title}
        </Text>
      </Box>
      <Stack
        borderBottomRightRadius="inherit"
        borderTopRightRadius="inherit"
        padding={containerPadding}
        direction={direction}
        spcaing={spacing}
        w="100%"
        overflowY={overflowY}
      >
        {children}
      </Stack>
    </Stack>
  );
}
