import React from "react";
import BasicMetricView from "./BasicMetricView";
import CopyComponent from "components/CopyComponent";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";

const StatusViewAwaitingPayment = ({ data: row }) => {
  return (
    <>
      <GroupingContainer flex={1} title={"Payee Information"}>
        <BasicMetricView
          data={[
            {
              label: "Name",
              value: (
                <CopyComponent
                  value={[
                    row?.personalDetails?.firstName,
                    row?.personalDetails?.lastName,
                  ].join(" ")}
                />
              ),
            },
            { label: "Email", value: <CopyComponent value={row?.email} /> },
            {
              label: "Street Address",
              value: (
                <CopyComponent value={row?.personalDetails?.address?.street} />
              ),
            },
            {
              label: "Postal Code",
              value: (
                <CopyComponent value={row?.personalDetails?.address?.zip} />
              ),
            },
            {
              label: "Region",
              value: (
                <CopyComponent value={row?.personalDetails?.address?.region} />
              ),
            },
            {
              label: "Country",
              value: (
                <CopyComponent value={row?.personalDetails?.address?.country} />
              ),
            },
            {
              label: "Phone",
              value: (
                <CopyComponent value={row?.personalDetails?.phone || "N/A"} />
              ),
            },
          ]}
        />
      </GroupingContainer>
      <GroupingContainer flex={1} title={"Payment Information"}>
        <BasicMetricView
          data={[
            { label: "Payment Method", value: row?.payoutMethod },
            {
              label: "Account Number",
              value: (
                <CopyComponent
                  value={
                    row?.payoutDetails?.find(
                      (detail) => detail.type === "Account Number"
                    )?.value
                  }
                />
              ),
            },
            {
              label: "IBAN",
              value: (
                <CopyComponent
                  value={
                    row?.payoutDetails?.find((detail) => detail.type === "IBAN")
                      ?.value
                  }
                />
              ),
            },
            {
              label: "BIC / Swift",
              value: (
                <CopyComponent
                  value={
                    row?.payoutDetails?.find(
                      (detail) =>
                        detail.type === "BIC" || detail.type === "Swift"
                    )?.value
                  }
                />
              ),
            },
            {
              label: "Bank Location",
              value: <CopyComponent value={row?.bankCountry} />,
            },
          ]}
        />
      </GroupingContainer>
    </>
  );
};

export default StatusViewAwaitingPayment;
