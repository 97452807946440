import { MdTag } from "react-icons/md";
import { FormInput } from "components/FormInput";
import { VStack } from "@chakra-ui/react";
import {
  isValidIBAN,
  isValidBIC,
  getCountrySpecifications,
  electronicFormatIBAN,
} from "ibantools";
import { useEffect, useMemo } from "react";

export default function BankDetailsForm({
  bankInf,
  control,
  setValue,
}) {
  const showIBAN = useMemo(() => {
    const countries = getCountrySpecifications();
    return countries[bankInf.code]
      ? countries[bankInf.code].IBANRegistry
      : false;
  }, [bankInf]);

  useEffect(() => {
    if (bankInf) {
      if (showIBAN) {
        setValue("accountNumber", "");
      } else {
        setValue("IBAN", "");
      }
    }
  }, [bankInf, setValue, showIBAN]);
  return (
    <VStack>
      {showIBAN ? (
        <FormInput
          id="IBAN"
          label="IBAN"
          onChange={(e) => {
            setValue("IBAN", e.target.value.toUpperCase().replace(/\s/g, ""));
          }}
          onBlur={(e) => {
            setValue("IBAN", electronicFormatIBAN(e.target.value));
          }}
          icon={<MdTag color="gray.300" />}
          rules={{
            required: "This field is required",
            validate: (value) => {
              return isValidIBAN(value) || "Invalid IBAN number";
            },
          }}
          control={control}
        />
      ) : null}
      {!showIBAN ? (
        <FormInput
          id="accountNumber"
          label="Account Number"
          icon={<MdTag color="gray.300" />}
          rules={{
            required: "This field is required",
          }}
          onChange={(e) => {
            // uppercase and replace all whitespaces
            setValue(
              "accountNumber",
              e.target.value.toUpperCase().replace(/\s/g, "")
            );
          }}
          control={control}
        />
      ) : null}

      <FormInput
        id="BIC"
        label="SWIFT / BIC"
        icon={<MdTag color="gray.300" />}
        control={control}
        rules={{
          required: "This field is required",
          validate: (value) => {
            return isValidBIC(value) || "Invalid SWIFT / BIC number";
          },
        }}
        onChange={(e) => {
          setValue("BIC", e.target.value.toUpperCase().replace(/\s/g, ""));
        }}
      />
    </VStack>
  );
}
