// Chakra imports
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  VStack,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
// Custom components
import React from "react";
// Assets
import { useState } from "react";

export default function PasswordReset() {
  const [show, setShow] = React.useState(false);
  const [errMsg, setErrMsg] = useState("");
  const handleClick = () => setShow(!show);

  const [pwd, setPwd] = useState("");

  const handlePwdChange = async () => {
    setErrMsg("Password change not implemented.");
    return;
    // @todo: Implement password change
    // https://github.com/FundedTradingPlus/crm/issues/153
    // try {
    //   const client = Client();
    //   await client.post(
    //     `/api/auth/change-password`,
    //     JSON.stringify({
    //       token: JSON.parse(localStorage.getItem("user"))?.refreshToken?.token,
    //       password: pwd,
    //     })
    //   );
    //   toast.success(ALERT_MESSAGE.USER_PASSWORD_RESET_SUCCESS);
    // } catch (err) {
    //   if (!err?.response) {
    //     setErrMsg("No Server Response");
    //   } else if (err.response?.status === 400) {
    //     setErrMsg("Missing Username or Password");
    //   } else if (err.response?.status === 401) {
    //     setErrMsg("Unauthorized");
    //   } else {
    //     setErrMsg("Login Failed");
    //   }
    // }
  };

  return (
    <VStack>
      <Heading size="md">Change Your Password</Heading>
      <FormControl variant="floating">
        <Input
          id="new-password"
          isRequired={true}
          type="password"
          onChange={(e) => setPwd(e.target.value)}
          value={pwd}
        />
        <FormLabel>Password</FormLabel>
      </FormControl>

      <FormControl variant="floating">
        <InputGroup>
          <Input
            id="confirm-password"
            isRequired={true}
            type={show ? "text" : "password"}
          />
          <InputRightAddon>
            <Icon
              _hover={{ cursor: "pointer" }}
              as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              onClick={handleClick}
            />
          </InputRightAddon>
        </InputGroup>
        <FormLabel>Repeat Password</FormLabel>
      </FormControl>
      {errMsg}
      <Button colorScheme="green" onClick={() => handlePwdChange()}>
        Change password
      </Button>
    </VStack>
  );
}
