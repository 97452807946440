import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MdExpandMore } from "react-icons/md";

const CheckboxMenu = ({
  children,
  items,
  onChange,
  selectedValues = [],
  indicateSelection = false,
  autoClose = false,
  size = "md",
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selection, setSelection] = useState(selectedValues);
  const changed = useMemo(() => {
    return JSON.stringify(selection) !== JSON.stringify(selectedValues);
  }, [selection, selectedValues]);

  useEffect(() => {
    // trigger onchange only when the menu closes and selection is changed
    if (!isOpen && changed) {
      onChange && onChange(selection);
    }
  }, [isOpen, onChange, changed, selection]);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FormControl style={{ zIndex: 100, width: "auto" }}>
      <Menu
        isOpen={isOpen}
        onClose={(e) => {
          if (autoClose) {
            setIsOpen(false);
          }
        }}
      >
        <MenuButton
          {...props}
          as={Button}
          colorScheme={
            indicateSelection && selection && selection.length ? "orange" : null
          }
          rightIcon={
            isOpen ? (
              <MdExpandMore className="icon-rotate-180 icon-rounded" />
            ) : (
              <MdExpandMore className="icon-rounded" />
            )
          }
          size={size}
          onClick={handleMenuToggle}
        >
          {children}
        </MenuButton>
        <MenuList>
          <CheckboxGroup
            name="status"
            defaultValue={selection}
            onChange={(selected) => {
              setSelection(selected);
            }}
          >
            {items.map((item) => (
              <MenuItem key={item.value}>
                <FormLabel
                  htmlFor={item.value}
                  style={{ display: "flex", gap: 8 }}
                  m={0}
                  onClick={(e) => {
                    if (e.target.tagName === "P") {
                      // if label and autoClose is false, close the menu
                      if (!autoClose) {
                        setIsOpen(false);
                      }
                    }
                  }}
                >
                  <Checkbox
                    id={item.value}
                    key={item.value}
                    value={item.value}
                    variant="button"
                  ></Checkbox>
                  <Text>{item.title}</Text>
                </FormLabel>
              </MenuItem>
            ))}
          </CheckboxGroup>
        </MenuList>
      </Menu>
    </FormControl>
  );
};

export default CheckboxMenu;
