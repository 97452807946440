export const Form = {
  variants: {
    floating: {
      container: {
        mt: 4,
        label: {
          top: "-20px",
          left: "0px",
          zIndex: 2,
          position: "absolute",
          backgroundColor: "inherit",
          pointerEvents: "none",
          borderRadius: "5px",
          bg: "navy.900",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "left top",
          fontSize: "0.9em",
        },
        input: {
          bg: "navy.900",
        },
        select: {
          bg: "navy.900",
        },
      },
    },
  },
};
