import React from "react";
import BasicMetricView from "./BasicMetricView";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import DateTime from "components/DateTime";

const StatusViewFinalized = ({ data: row }) => {
  return (
    <>
      <GroupingContainer flex={1} title={"Payee Information"}>
        <BasicMetricView
          data={[
            {
              label: "Name",
              value:
                row.personalDetails?.firstName +
                " " +
                row.personalDetails?.lastName,
            },
            { label: "Email", value: row?.email },
            {
              label: "Street Address",
              value: row.personalDetails?.address?.street,
            },
            { label: "Postal Code", value: row.personalDetails?.address?.zip },
            { label: "Region", value: row.personalDetails?.address?.region },
            { label: "Country", value: row.personalDetails?.address?.country },
            { label: "Phone", value: row.personalDetails?.phone || "N/A" },
          ]}
        />
      </GroupingContainer>
      <GroupingContainer flex={1} title={"Payment Information"}>
        <BasicMetricView
          data={[
            { label: "Payment Method", value: row?.payoutMethod },
            {
              label: "Account Number",
              value: row?.payoutDetails?.find(
                (detail) => detail.type === "Account Number"
              )?.value,
            },
            {
              label: "IBAN",
              value: row?.payoutDetails?.find(
                (detail) => detail.type === "IBAN"
              )?.value,
            },
            {
              label: "BIC / Swift",
              value: row?.payoutDetails?.find(
                (detail) => detail.type === "BIC" || detail.type === "Swift"
              )?.value,
            },
            { label: "Bank Location", value: row?.bankCountry },
            {
              label: "Paid On",
              value: <DateTime isoString={row?.paidAt} />,
            },
          ]}
        />
      </GroupingContainer>
    </>
  );
};

export default StatusViewFinalized;
