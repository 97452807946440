import { Badge, Tooltip } from "@chakra-ui/react";

export const StatusBadge = ({ color, topicIcon, topicName, statusText, badgeIcon, onClickHandler, size = 'sm' }) => {
  return (
    <Tooltip label={`${topicName} status: ${statusText}`}>
      <Badge colorScheme={color} size={size} onClick={onClickHandler} display='flex' flexGrow={0} >
        {topicIcon}&nbsp;
        {statusText}&nbsp;
        {badgeIcon}
      </Badge>
    </Tooltip>
  );
};