import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { pickBy, isEmpty } from "lodash";

const DEFAULT_HEADER = {};
const DEFAULT_BODY = {};
const DEFAULT_FOOTER = {};
const DEFAULT_BUTTON = {};

export default function ConfirmationModal({
  header: { props: headerProps, children: headerChildren } = DEFAULT_HEADER,
  body: { props: bodyProps, children: bodyChildren } = DEFAULT_BODY,
  footer: {
    props: footerProps,
    action: {
      text: actionButtonText,
      props: actionButtonProps,
      onConfirmation,
    } = DEFAULT_BUTTON,
  } = DEFAULT_FOOTER,
  disclosure: { isOpen, onClose } = {},
}) {
  const { handleSubmit, control, reset } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen, reset]);

  const onSubmit = (formControlState) => {
    const sanitizedStateNoEmptyStrings = pickBy(
      formControlState,
      (value) => !(typeof value === "string" && isEmpty(value))
    );
    onConfirmation(sanitizedStateNoEmptyStrings);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader {...headerProps} pr={10}>
            {React.Children.map(headerChildren, (child) => {
              if (!React.isValidElement(child)) return child;
              return React.cloneElement(child, { ...child.props });
            })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody {...bodyProps}>
            <VStack gap={1} px={0}>
              {React.Children.map(bodyChildren, (child) => {
                if (!React.isValidElement(child))
                  return <Text w="full">{child}</Text>;
                if (child.type.displayName === "Text") return child;

                const {
                  name = "",
                  placeholder = "",
                  defaultValue = "",
                  rules = null,
                } = child.props;
                return (
                  <Controller
                    key={name}
                    name={name}
                    placeholder={placeholder}
                    control={control}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl isInvalid={!!error}>
                        {React.cloneElement(child, {
                          ...field,
                          ...child.props,
                        })}
                        <FormErrorMessage>{error?.message}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                );
              })}
            </VStack>
          </ModalBody>

          <ModalFooter {...footerProps}>
            <Button {...actionButtonProps} type="submit">
              <Text>{actionButtonText}</Text>
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
