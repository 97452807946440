// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import UserManage from "views/admin/userManagement/components/UserManage";

// Assets
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthProvider";

export default function UserManagement() {
  let navigate = useNavigate();

  const { auth } = useAuth();

  useEffect(() => {
    if (
      auth?.user &&
      auth?.user.role !== "opsAdmin" &&
      auth?.user.role !== "masterAdmin"
    ) {
      navigate("/admin/passes");
    }
  }, [auth?.user, navigate]);

  return (
    <Box>
      {/* Main Fields */}

      <Grid
        mb="20px"
        templateColumns={{
          base: "1fr",
        }}
        templateRows="1fr"
        gap={{ base: "20px", xl: "20px" }}
      >
        <UserManage />
      </Grid>
    </Box>
  );
}
