import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/form-control";
import { InputGroup } from "@chakra-ui/input";
import { RadioGroup } from "@chakra-ui/react";
import { useController } from "react-hook-form";

export function RadioGroupInput({
  id,
  placeholder,
  helperText,
  control,
  rules,
  inputStyle,
  type,
  defaultValue,
  children,
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: id,
    control,
    rules,
  });

  return (
    <FormControl isInvalid={error}>
      <InputGroup>
        <RadioGroup
          {...inputStyle}
          id={id}
          placeholder={placeholder}
          onChange={field.onChange} // send value to hook form
          onBlur={field.onBlur} // notify when input is touched/blur
          value={field.value} // input value
          name={field.name} // send down the input name
          ref={field.ref}
          type={type}
          defaultValue={defaultValue}
        >
          {children}
        </RadioGroup>
      </InputGroup>
      {error && <FormErrorMessage>{error && error.message}</FormErrorMessage>}
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
