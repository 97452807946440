import { MdOpenInNew } from "react-icons/md";
import { useMemo } from "react";
import { StatusBadge } from "./StatusBadge";

export const MetaDataStatusBadge = ({
  topicIcon,
  topicName,
  metaData,
  linkOut,
  onClick,
  onClickIcon,
  size = "sm",
}) => {
  let color;
  let statusText;

  const badgeIcon = useMemo(() => {
    if (onClickIcon) {
      return onClickIcon;
    } else if (linkOut) {
      return <MdOpenInNew mx="2px" />;
    }
  }, [onClickIcon, linkOut]);

  if (!metaData) {
    return null;
  } else if (metaData.complete) {
    color = "green";
    statusText = "Complete";
  } else if (metaData.invite) {
    color = "yellow";
    statusText = "Invite Sent";
  } else {
    color = "purple";
    statusText = "Required";
  }

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    } else if (linkOut) {
      window.open(linkOut, "_blank");
    }
  };

  return (
    <StatusBadge
      color={color}
      topicIcon={topicIcon}
      topicName={topicName}
      statusText={statusText}
      badgeIcon={badgeIcon}
      onClickHandler={onClickHandler}
      size={size}
    />
  );
};
