// Chakra imports
import { Button, Select, Heading, HStack, VStack } from "@chakra-ui/react";
import validator from "validator";
import { toast } from "react-toastify";
import { accountTypes, purposeTypes } from "../constant";
import { ALERT_MESSAGE } from "variables/message";

export default function SecondForm(props) {
  const { preStep, nextStep, formData, setFormData } = props;
  const toNext = () => {
    if (validator.isEmpty(formData.accountType)) {
      toast.error(ALERT_MESSAGE.PAYOUT_REQUIRE_ACCOUNT_TYPE);
    } else {
      nextStep();
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Payout Information Form</Heading>

      <Select
        id="user_type"
        placeholder="Account Type"
        onChange={(e) =>
          setFormData({ ...formData, accountType: e.target.value })
        }
        value={formData.accountType}
      >
        <option value={accountTypes.BANK_INDIVIDUAL}>
          Bank Owned by Individual
        </option>
        <option value={accountTypes.BANK_COMPANY}>Bank Owned by Company</option>

        {purposeTypes.IFXTEST !== formData.purpose && (
          <option value={accountTypes.CRYPTOCURRENCY_WALLET}>
            Cryptocurrency Wallet
          </option>
        )}
      </Select>
      <HStack spacing={4} justifyContent="space-between">
        <Button variant="ghost" onClick={preStep}>
          Back
        </Button>
        <Button colorScheme="green" onClick={toNext}>
          Next
        </Button>
      </HStack>
    </VStack>
  );
}
