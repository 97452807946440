import {
  Badge,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { MdDescription, MdPerson } from "react-icons/md";
import {
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { MdOutlineEmail, MdRemoveRedEye } from "react-icons/md";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Client } from "api/axios";
import debounce from "lodash.debounce";
import React, { useEffect } from "react";
import { MdSearch } from "react-icons/md";

import { Link } from "react-router-dom";
import Pagination, { PAGE_SIZES } from "components/pagination/pagination";
import AddressFormatted from "components/AddressFormatted";
import { formatMoney } from "variables/utils";
import { MetaDataStatusBadge } from "../passes/components/MetaDataStatusBadge";

export default function Overview() {
  const column_helper = createColumnHelper();
  const [pageSize, setPageSize] = React.useState(PAGE_SIZES["md"]);
  const [page, setPage] = React.useState(1);
  const [currentClient, setCurrentClient] = React.useState({});
  const [searchFilter, setSearchFilter] = React.useState(null);
  const debouncedSetFilter = debounce(setSearchFilter, 500);
  const emailModal = useDisclosure();
  const queryClient = useQueryClient();

  async function fetchClientsData() {
    const search_query = searchFilter ? `&name=${searchFilter}` : "";

    const api_url = `/api/client?limit=${pageSize}&page=${page}${search_query}&populate=accounts`;
    return await Client(true).get(api_url);
  }

  const { data: queryData, isFetched } = useQuery({
    queryKey: [`/api/client/`, page, pageSize, searchFilter],
    queryFn: fetchClientsData,
    keepPreviousData: true,
    initialData: { queryData: { docs: [] } },
  });

  const selectUserAndOpenEmail = (row) => () => {
    setCurrentClient(row.original);
  };

  const TABLE_COLUMNS = [
    column_helper.accessor("name", {
      header: "Client",
      cell: ({ row }) =>
        row.original ? (
          <HStack>
            <VStack alignItems="left" w="full">
              <Text fontWeight={"bold"}>{row.original.name}</Text>
              <Text
                fontWeight={"light"}
                wordBreak={"break-all"}
                noOfLines={2}
                mt="2px !important"
                fontSize={"sm"}
              >
                {row.original.email}
              </Text>
            </VStack>
          </HStack>
        ) : (
          row.groupByVal
        ),
    }),
    column_helper.accessor("address", {
      header: "Address",
      cell: ({ row }) => <AddressFormatted {...row.original.address} />,
    }),
    column_helper.accessor("accounts", {
      header: "# Accounts",
      cell: ({ row }) => (
        <Flex gap={1} wrap={"wrap"}>
          <Badge size="small" colorScheme="green">
            {row.original.accountStats.passed} passed
          </Badge>
          <Badge size="small" colorScheme="yellow">
            {row.original.accountStats.active} active
          </Badge>
          <Badge size="small" colorScheme="red">
            {row.original.accountStats.breached} breached
          </Badge>
        </Flex>
      ),
    }),
    column_helper.accessor("status", {
      header: "Status",
      cell: ({ row }) => {
        const kycMeta = row.original.metadata?.kyc;
        const kycLink =
          kycMeta?.source && kycMeta?.source !== "manual"
            ? `https://cockpit.sumsub.com/checkus#/applicant/${kycMeta.source}/client/basicInfo`
            : undefined;
        return (
          <VStack alignItems={"flex-start"}>
            <MetaDataStatusBadge
              topicName={"KYC"}
              topicIcon={<MdPerson />}
              metaData={kycMeta}
              linkOut={kycLink}
            />
            <MetaDataStatusBadge
              topicName={"Contract"}
              topicIcon={<MdDescription />}
              metaData={row.original.metadata?.contracted}
              onClickIcon={undefined}
            />
          </VStack>
        );
      },
    }),
    column_helper.accessor("metadata.totalPayouts", {
      header: "Total Payouts",
      cell: ({ row }) => (
        <Text>{formatMoney(row.original.metadata?.totalPayouts || 0)}</Text>
      ),
    }),
    column_helper.accessor("lifetimeValue", {
      header: "Lifetime Value",
      cell: ({ row }) => <Text>{formatMoney(row.original.lifetimeValue)}</Text>,
    }),
    column_helper.accessor("accountStats", {
      header: "Open Risk",
      cell: ({ row }) => (
        <Text>
          {formatMoney(row.original.accountStats?.totalOpenRisk || 0)}
        </Text>
      ),
    }),
    {
      id: "actions",
      cell: ({ row }) => (
        <HStack alignItems={"flex-start"}>
          <Link to={`/admin/clients/${row.original.id}`}>
            <Button
              title="View Client"
              iconSpacing={0}
              leftIcon={<MdRemoveRedEye />}
              size="xs"
            ></Button>
          </Link>
          <Button
            leftIcon={<MdOutlineEmail />}
            size="xs"
            title="Send Email"
            iconSpacing={0}
            onClick={selectUserAndOpenEmail(row)}
          ></Button>
        </HStack>
      ),
      isPlaceholder: true,
    },
  ];

  const { getHeaderGroups, getRowModel, resetExpanded } = useReactTable({
    columns: TABLE_COLUMNS,
    data: queryData.data?.docs || [],
    getRowCanExpand: (row) =>
      row.original.status !== "rejected" && row.original.status !== "flagged",
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(() => {
    if (queryData && page > queryData.data?.totalPages) {
      setPage(queryData.data.page);
    }
    resetExpanded();
  }, [queryData, pageSize, page, resetExpanded]);

  useEffect(() => {
    if (currentClient && currentClient.id) {
      emailModal.onOpen();
    }
  }, [currentClient, emailModal]);

  useEffect(() => {
    if (!emailModal.isOpen) {
      setCurrentClient({});
    }
  }, [emailModal.isOpen]);

  return (
    <QueryClientProvider client={queryClient}>
      <TableContainer overflowY={"none"} overflowX={"none"}>
        <HStack
          justify="space-evenly"
          gap="10px"
          colSpan={getHeaderGroups()[0].headers.length}
          p="1% !important"
        >
          <Flex flexGrow={1} gap="10px">
            <InputGroup
              border={"1px solid"}
              borderColor={"#0A1129"}
              rounded={"lg"}
              bg={"#111D45"}
            >
              <InputLeftElement>
                <MdSearch color="gray.500" />
              </InputLeftElement>
              <Input
                color={"white"}
                placeholder="Search by name or email"
                onChange={(e) => {
                  debouncedSetFilter(e.target.value);
                }}
              />
            </InputGroup>
          </Flex>
        </HStack>
        <Skeleton
          isLoaded={isFetched}
          rounded={"xl"}
          h="68vh"
          overflowY={"scroll"}
          w="98%"
          sx={{ margin: "1% !important" }}
          bg={"#111D45"}
          pb="20px"
        >
          <Table size="sm" fontSize="xs">
            <Thead
              css={{
                position: "-webkit-sticky",
              }}
              position="sticky"
              top="0"
              zIndex="1"
              bg={"#111D45"}
            >
              {getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} p={"10px"}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {getRowModel().rows.map((row) => (
                <React.Fragment key={"fragment-" + row.id}>
                  <Tr key={"row-" + row.id} margin="10px">
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        whiteSpace={"wrap"}
                        p={"10px"}
                        borderBottomWidth={row.getIsExpanded() ? "0" : "1px"}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </Skeleton>
        <Flex>
          {queryData.data && (
            <Pagination
              setPage={setPage}
              data={queryData}
              setPageSize={setPageSize}
            />
          )}
        </Flex>
      </TableContainer>
    </QueryClientProvider>
  );
}
