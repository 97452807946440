import React, { useEffect, useState } from "react";

// chakra imports
import { Box, useColorModeValue } from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";

import PropTypes from "prop-types";

// Assets
import { MdMenu, MdMenuOpen } from "react-icons/md";

import "./sidebar.css";
import { useNavigate } from "react-router-dom";
import { useUserRoutes } from "contexts/UserRoutesProvider";
function Sidebar() {
  const { routes } = useUserRoutes();

  const navigate = useNavigate();

  const [expandMobile, setExpandMobile] = useState(false);
  useEffect(() => {
    setExpandMobile(false);
  }, [navigate]);

  let variantChange = "0.1s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");

  // SIDEBAR
  return (
    <Box
      className={`sidebar-wrp ${expandMobile ? "expand-mobile" : ""}`}
      w={"16rem"}
      ml={{ base: "-15rem", xl: 0 }}
      position="fixed"
    >
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="100%"
        h="100vh"
        overflow="hidden"
        boxShadow={shadow}
      >
        <Content routes={routes} />
      </Box>
      <Box
        position={"absolute"}
        right={"-2.5rem"}
        top={"3px"}
        width={"2.5rem"}
        height={"2.5rem"}
        style={{ background: "rgb(17, 28, 68)", borderRadius: "0 7px 7px 0" }}
        display={{ base: "block", xl: "none" }}
      >
        <Box onClick={() => setExpandMobile(!expandMobile)} cursor="pointer">
          <Box
            w={"2.5rem"}
            h={"2.5rem"}
            m={"auto"}
            color={"white"}
            fontSize={"1.5rem"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {expandMobile ? <MdMenuOpen size={30} /> : <MdMenu size={30} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
