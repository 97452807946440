export const columnsDataUser = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Password",
    accessor: "password",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
