// Chakra imports
import {
  Button,
  Text,
  InputGroup,
  HStack,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import validator from "validator";
import { toast } from "react-toastify";
import { accountTypes } from "../constant";
import { Client } from "api/axios";
import { ALERT_MESSAGE } from "variables/message";
import short from "short-uuid";

export default function SignatureForm(props) {
  const { preStep, pre2Step, nextStep, formData, setFormData } = props;
  const [isloading, SetIsLoading] = useState(false);
  const translator = short();

  var sigPad = {};
  var trimmedDataURL;

  const toBack = () => {
    if (
      validator.equals(formData.accountType, accountTypes.CRYPTOCURRENCY_WALLET)
    ) {
      pre2Step();
    } else {
      preStep();
    }
  };

  const toNext = () => {
    SetIsLoading(true);
    trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
    if (sigPad.isEmpty()) {
      toast.error(ALERT_MESSAGE.PAYOUT_REQUIRE_SIGN);
    } else {
      setFormData({
        ...formData,
        signature: trimmedDataURL,
      });
      updatePayout();
    }
  };

  const updatePayout = async () => {
    try {
      const payload = {
        invoice: translator.generate(),
        payoutMethod:
          formData.accountType === accountTypes.CRYPTOCURRENCY_WALLET
            ? "cryptocurrency"
            : "bank",
        payoutDetails: [
          { type: "Routing Number", value: formData?.routingNumber },
          { type: "Account Number", value: formData?.accountNumber },
          { type: "IBAN", value: formData?.IBAN },
          { type: "BIC", value: formData?.BIC },
        ],
        // New Fields...
        accountType: formData.accountType,
        personalDetails: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          address: {
            street: formData.street,
            city: formData.city,
            region: formData.region,
            country: formData.country,
            zip: formData.zipCode,
          },
          signature: trimmedDataURL,
        },
      };

      if (formData.accountType !== accountTypes.CRYPTOCURRENCY_WALLET) {
        payload.bankCountry = formData.bankCountry;
      }

      const client = Client(true);
      const response = await client.patch(
        `/api/form/?token=${localStorage.getItem("pToken")}`,
        JSON.stringify(payload)
      );
      if (response.status === 200) {
        toast.success(ALERT_MESSAGE.PAYOUT_INFORMATION_FILLING_SUCCESS);

        nextStep();
      }
    } catch (err) {
      const message =
        err.response?.data?.message || ALERT_MESSAGE.PAYOUT_FILLING_FAIL;
      toast.error(message);
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Payout Information Form</Heading>
      <Text>
        By signing below, you agree that the information here is correct and any
        typographical errors may result in your payout being delayed or lost.
      </Text>

      <InputGroup>
        <SignatureCanvas
          penColor="white"
          canvasProps={{ className: "sigCanvas" }}
          ref={(ref) => {
            sigPad = ref;
          }}
        />
      </InputGroup>
      <HStack spacing={4} justify="space-between">
        <Button variant="ghost" onClick={toBack}>
          Back
        </Button>
        <Button colorScheme={"green"} onClick={toNext} disabled={isloading}>
          Submit
        </Button>
      </HStack>
    </VStack>
  );
}
