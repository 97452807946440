import React, { useMemo } from "react";
import { MdDelete, MdEdit, MdPersonAddAlt1, MdSearch } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { toast } from "react-toastify";

// Custom components
import { Client } from "api/axios";
import Emitter from "api/emitter";
import Card from "components/card/Card";
import { ALERT_MESSAGE } from "variables/message";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const initial_user_details = {
  name: "",
  email: "",
  role: "",
  password: "",
  id: undefined,
  isEdit: false,
};

function user_details_reducer(state, action) {
  switch (action.type) {
    case "set":
      return {
        ...state,
        ...action.payload,
      };
    case "reset":
      return initial_user_details;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export default function ColumnsTable({ columnsData, tableData }) {
  const [state, dispatch] = React.useReducer(
    user_details_reducer,
    initial_user_details
  );
  const { isEdit, id, name, email, role, password } = state;
  const cancelRef = React.useRef();
  const [savedPageIndex, setSavedPageIndex] = React.useState(0);
  const queryClient = useQueryClient();

  const api_request = {
    create: () =>
      Client(true).post(
        `/api/users`,
        JSON.stringify({
          name: name,
          email: email,
          password: password,
          role: role,
        })
      ),
    update: () =>
      Client(true).patch(
        `/api/users/${id}`,
        JSON.stringify({
          name: name,
          email: email,
          password: password,
          role: role,
        })
      ),
    delete: () => Client(true).delete(`/api/users/${id}`),
  };

  const crud_mutation = useMutation({
    mutationFn: (request) => request(),
    onSuccess: (data) => {
      const { method } = data.config;
      const action =
        method === "delete"
          ? "user_delete"
          : method === "post"
            ? "user_create"
            : "user_update";
      Emitter.emit(action);
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success(ALERT_MESSAGE.USER_UPDATE_SUCCESS);
      setSavedPageIndex(pageIndex); // remember the page index when are currently on so we can go back to it after the mutation
    },
  });

  function set_selected_user_details(cell, args = {}) {
    dispatch({
      type: "set",
      payload: {
        name: cell[0].value,
        email: cell[1].value,
        role: cell[2].value,
        password: cell[3].value,
        id: cell[0].row.original.id,
        ...args,
      },
    });
  }
  function reset_selected_user_details() {
    dispatch({ type: "reset" });
  }

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10, pageIndex: savedPageIndex }, // Initial page size & index
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: delIsOpen,
    onOpen: delOnOpen,
    onClose: delOnClose,
  } = useDisclosure();

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      py="10px"
    >
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {/* Search & Filter */}
          <Tr>
            <Th colSpan={headerGroups[0].headers.length} px="10px">
              <Flex justify="space-between" gap="10px">
                <InputGroup>
                  <Input isDisabled placeholder="Search..." />
                  <InputRightElement disabled>
                    <MdSearch color="gray.500" disabled />
                  </InputRightElement>
                </InputGroup>
                <IconButton
                  icon={<MdPersonAddAlt1 />}
                  bg="transparent"
                  color="white"
                  size="md"
                  cursor="pointer"
                  onClick={() => {
                    reset_selected_user_details();
                    onOpen();
                  }}
                />
              </Flex>
            </Th>
          </Tr>
          {/* Table header Groups */}
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  py="10px"
                  px="10px"
                  key={index}
                  borderColor="transparent"
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data;

                  switch (cell.column.Header) {
                    case "Name":
                      data = (
                        <Flex align="center">
                          <Text color="white" fontSize="sm" fontWeight="700">
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                      break;
                    case "Email":
                    case "Role":
                      data = (
                        <Flex align="center">
                          <Text color="white" fontSize="sm" fontWeight="700">
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                      break;

                    case "Password":
                      data = (
                        <Flex align="center">
                          <Text color="gray.500" fontSize="sm" fontWeight="700">
                            **********
                          </Text>
                        </Flex>
                      );
                      break;

                    case "Action":
                      data = (
                        <Flex cursor="pointer">
                          <IconButton
                            icon={<MdEdit />}
                            color="white"
                            size="md"
                            // mr="auto"
                            bg="transparent"
                            onClick={() => {
                              set_selected_user_details(row.cells, {
                                isEdit: true,
                              });
                              onOpen();
                            }}
                          />
                          <IconButton
                            icon={<MdDelete />}
                            color="white"
                            size="md"
                            mr="auto"
                            bg="transparent"
                            onClick={() => {
                              set_selected_user_details(row.cells, {
                                isEdit: false,
                              });
                              delOnOpen();
                            }}
                          />
                        </Flex>
                      );
                      break;

                    default:
                      data = (
                        <Text color="gray.500" fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                      break;
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                      py="10px"
                      px={cell.column.Header === "Action" ? "0px" : "10px"}
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {/* Pagination */}
      <Flex justify="space-between" align="center" px="25px" mt="20px">
        <Flex align="center" width="full">
          <Text fontSize="sm" color="gray.400" mr="2">
            Page
          </Text>
          <Select
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            fontWeight="500"
            size="sm"
            value={pageIndex + 1}
            width="auto"
            cursor="pointer"
            onChange={(e) => gotoPage(Number(e.target.value) - 1)}
          >
            {pageOptions.map((option, index) => (
              <option key={index} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </Select>
          <Text fontSize="sm" color="gray.400" ml="2">
            of {pageOptions.length}
          </Text>
        </Flex>
        <Flex align="center">
          <Button
            variant="ghost"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </Button>
          <Button
            variant="ghost"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            ml="2"
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User Register/Edit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl variant="floating">
              <Input
                isRequired={true}
                onChange={(e) =>
                  dispatch({ type: "set", payload: { name: e.target.value } })
                }
                value={name}
              />
              <FormLabel>Name</FormLabel>
            </FormControl>
            <FormControl variant="floating">
              <Input
                isRequired={true}
                type="email"
                onChange={(e) =>
                  dispatch({ type: "set", payload: { email: e.target.value } })
                }
                value={email}
              />
              <FormLabel>Email</FormLabel>
            </FormControl>
            <FormControl variant="floating">
              <Select
                placeholder="Select role"
                isRequired={true}
                onChange={(e) =>
                  dispatch({ type: "set", payload: { role: e.target.value } })
                }
                value={role}
                defaultValue="opsContributor"
              >
                <option value="affiliatesContributor">
                  Contributor (Affiliates)
                </option>
                <option value="opsContributor">Contributor (Ops)</option>
                <option value="opsManager">Manager (Ops)</option>
                <option value="opsAdmin">Admin (Ops)</option>
                <option value="masterAdmin">Master Admin</option>
              </Select>
              <FormLabel>Role</FormLabel>
            </FormControl>
            <FormControl variant="floating">
              <Input
                isRequired={true}
                type="password"
                onChange={(e) =>
                  dispatch({
                    type: "set",
                    payload: { password: e.target.value },
                  })
                }
                value={password}
              />
              <FormLabel>Password</FormLabel>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                isEdit
                  ? crud_mutation.mutate(api_request.update)
                  : crud_mutation.mutate(api_request.create);
                onClose();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={delIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={delOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={delOnClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => {
                  crud_mutation.mutate(api_request.delete);
                  delOnClose();
                }}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
}
