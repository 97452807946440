import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
} from "@chakra-ui/react";
import CopyTradingReport from "components/CopyTradingReport";
import Card from "components/card/Card";
import { formatter } from "variables/utils";
export default function CopyTradingReportModal({
  onClose,
  account,
  report,
}) {
  // check if report.timeUpdated is a number
  report.timeUpdated = new Date(account.report.timeUpdated * 1000);


  return (
    <Modal size={"6xl"} isOpen={report} onClose={onClose}>
      <ModalOverlay />;
      <ModalContent>
        <ModalHeader>Copy Trading Report</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card mb={3}>
            <Table>
              <thead>
                <tr>
                  <th>Report Generated</th>
                  <th>Analyzed Trades</th>
                  <th>Number of Similar Trades</th>
                  <th>Account size</th>
                  <th>Profit From Trades</th>
                  <th>Profit From Winnings Trades</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    {report.timeUpdated.toLocaleDateString("en-US")}
                  </td>
                  <td align="center">{report.analyzedTrades}</td>
                  <td align="center">{report.countWithSimilarTrades}</td>
                  <td align="center">
                    {formatter.format(
                      account?.plan?.startingBalance
                        ? account.plan.startingBalance
                        : account.startingBalance
                    )}
                  </td>
                  <td align="center">
                    {formatter.format(report.profitFromWithSimilarTrades)}
                  </td>
                  <td align="center">
                    {isNaN(report.totalWinnings)
                      ? "n/a"
                      : formatter.format(report.totalWinnings)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
          <Card mb={3}>
            <CopyTradingReport data={{ report, account }} />
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
