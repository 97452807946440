import {
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import Card from "components/card/Card.js";
import { Client } from "api/axios";

// Custom components
import TradeAccountsTable from "views/admin/tradeAccounts/components/TradeAccountsTable";

function TradeAccounts({ client }) {
  const queryClient = useQueryClient();

  async function fetchClientAccounts() {
    if (!client.id) return;
    const sortBy = JSON.stringify([
      ["stats.passed", "desc"],
      ["stats.breached", "asc"],
    ]);
    const api_url = `/api/trade-accounts?client=${client.id}&populate=ftmsBreaches&limit=100&sortBy=${sortBy}`;
    return await Client(true).get(api_url);
  }

  const { data, isLoading } = useQuery({
    queryKey: [`/api/trade-accounts/`, client.id],
    queryFn: fetchClientAccounts,
    keepPreviousData: true,
    initialData: { data: { docs: [] } },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Card
        mb={{ base: "0px", lg: "20px" }}
        mt={4}
        style={{ alignItems: "flex-start" }}
      >
        {data ? (
          <TradeAccountsTable
            tableData={data.data}
            loading={isLoading}
            isClientView={true}
            onDataLoad={() => {
              queryClient.invalidateQueries({
                queryKey: [`/api/trade-accounts/`, client.id],
              });
            }}
          />
        ) : null}
      </Card>
    </QueryClientProvider>
  );
}

export default TradeAccounts;
