import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  InputRightAddon,
  Stack,
} from "@chakra-ui/react";
// Custom components
import AuthLayout from "layouts/auth/";
// Assets
import illustration from "assets/img/logoWhiteCompact.png";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FormInput } from "components/FormInput";
import { useAuth } from "contexts/AuthProvider";

function SignIn() {
  const [reCaptchaReady, setReCaptchaReady] = useState(false);
  const [refreshReCaptchaToken, setRefreshReCaptchaToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { control, setValue, handleSubmit } = useForm();

  const auth = useAuth();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const handleLogIn = async (form) => {
    if (!form.token) {
      toast.warning("Please complete the captcha");
    } else {
      auth.login(form.email, form.password, form.token);
      setRefreshReCaptchaToken((oldState) => !oldState);
    }
  };

  useEffect(() => {
    if (reCaptchaReady || refreshReCaptchaToken) {
      // store the token from grecaptcha
      window.grecaptcha
        .execute("6Ld1SJUpAAAAAMtKMqCDd4VbWcUe5M2INPFOBPMI", {
          action: "homepage",
        })
        .then((token) => {
          setValue("token", token);
        });
    }
  }, [reCaptchaReady, refreshReCaptchaToken, setValue]);

  const handleGoogleReCaptchaLoaded = () => {
    window.grecaptcha.ready(() => setReCaptchaReady(true));
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${"6Ld1SJUpAAAAAMtKMqCDd4VbWcUe5M2INPFOBPMI"}`;
    script.addEventListener("load", handleGoogleReCaptchaLoaded);
    document.body.appendChild(script);
  }, []);

  return (
    <AuthLayout image={illustration}>
      <Flex
        w="100%"
        maxW="420px"
        mx="auto"
        me="auto"
        h="100%"
        alignItems="stretch"
        justifyContent="center"
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <form onSubmit={handleSubmit(handleLogIn)}>
          <Stack
            zIndex="2"
            direction="column"
            background="transparent"
            spacing="2"
          >
            <FormInput
              id="email"
              type="email"
              placeholder={"Email address"}
              rules={{
                required: "This is required.",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              }}
              control={control}
              mt="0"
            />
            <FormInput
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder={"Password"}
              rules={{
                required: "This is required.",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              }}
              control={control}
              rightAddon={
                <InputRightAddon onClick={() => setShowPassword(!showPassword)}>
                  <Icon as={showPassword ? MdVisibilityOff : MdVisibility} />
                </InputRightAddon>
              }
              mt="0"
            />

            <Button
              type="submit"
              fontSize="sm"
              colorScheme="brand"
              fontWeight="500"
            >
              Sign In
            </Button>
          </Stack>
        </form>
      </Flex>
      <div
        className="g-recaptcha"
        data-sitekey="_reCAPTCHA_site_key_"
        data-size="invisible"
      ></div>
    </AuthLayout>
  );
}

export default SignIn;
