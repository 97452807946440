// Chakra imports
import { Text, VStack, Heading } from "@chakra-ui/react";

export default function SignatureForm() {
  return (
    <VStack spacing={4} alignItems="stretch">
      <Heading size="md">Payout Information Form</Heading>
      <Text>
        We have received all the information needed at this time. Please monitor
        your email for updates.
      </Text>
    </VStack>
  );
}
