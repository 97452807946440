import {
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Text,
  Spinner,
  Button,
  HStack,
  VStack,
} from "@chakra-ui/react";
import {
  useReactTable,
  getExpandedRowModel,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import Card from "components/card/Card";
import { Fragment, useEffect, useState } from "react";
import { MdExpandLess, MdExpandMore, MdLink } from "react-icons/md";

import { formatter } from "variables/utils";
import ExpandedRow from "./ExpandedRow";
import { Link } from "react-router-dom";
import { formatMoney } from "variables/utils";
import AccountAbstract from "components/AccountAbstract";

export default function TradeAccountsTable({
  tableData,
  isClientView,
  loading,
}) {
  const [data, setData] = useState(() => [...tableData.docs]);

  useEffect(() => {
    setData([...tableData.docs]);
  }, [tableData]);

  const columnHelper = createColumnHelper();

  const columns = [
    {
      id: "expander",
      header: () => (loading ? <Spinner size="xs" /> : ""),
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <Button
            size="xs"
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: "pointer" },
            }}
          >
            {row.getIsExpanded() ? <MdExpandLess /> : <MdExpandMore />}
          </Button>
        ) : (
          ""
        );
      },
    },
    columnHelper.accessor("stats", {
      header: "Info",
      cell: (info) => (
        <AccountAbstract account={info.row.original} showFpfxState />
      ),
    }),
  ];
  if (!isClientView) {
    columns.push(
      columnHelper.accessor("client.name", {
        header: "Client",
        cell: ({ row }) =>
          row.original ? (
            <VStack alignItems="left">
              <HStack>
                <Text>{row.original.client?.name}</Text>
                <Link to={`/admin/clients/${row.original.client?.id}`}>
                  <MdLink />
                </Link>
              </HStack>
              <Text>{row.original.client?.email}</Text>
            </VStack>
          ) : (
            row.groupByVal
          ),
      })
    );
  }
  columns.push(
    ...[
      columnHelper.accessor("plan.startingBalance", {
        header: "Size",
        cell: (info) =>
          formatMoney(info.row.original.plan
            ? info.row.original.plan.startingBalance
            : info.row.original.startingBalance, 0),
      }),
      columnHelper.accessor("plan.type", {
        header: "Program Type",
        cell: (info) => {
          return (
            <div>
              <p>
                {info.row.original.plan
                  ? info.row.original.plan.type
                  : info.row.original.type}
              </p>
              <p>
                {info.row.original.plan
                  ? info.row.original.plan.description
                  : info.row.original.description}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("stats.currentBalance", {
        header: "Balance",
        cell: (info) =>
          formatter.format(info.row.original.stats.currentBalance || 0),
      }),
      columnHelper.accessor("stats.openRisk", {
        header: "Open Risk",
        customSort: (a, b) =>
          (a.row.original.stats.openRisk ?? 0) -
          (b.row.original.stats.openRisk ?? 0),
        cell: (info) => formatter.format(info.row.original.stats.openRisk ?? 0),
      }),
    ]
  );

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Table size="sm" fontSize="xs">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={"fragment-" + row.id}>
              <Tr key={"row-" + row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
              {row.getIsExpanded() && (
                <Tr key={"row-exp-" + row.id}>
                  {/* 2nd row is a custom 1 cell row */}
                  <Td
                    borderBottomWidth={row.getIsExpanded() ? "1px" : "0"}
                    borderColor={
                      row.getIsExpanded()
                        ? "var(--chakra-colors-whiteAlpha-300)"
                        : "0"
                    }
                    colSpan={row.getVisibleCells().length}
                    style={{ overflow: "hidden" }}
                  >
                    <ExpandedRow row={row} />
                  </Td>
                </Tr>
              )}
            </Fragment>
          ))}
        </Tbody>
        <Tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
                </Th>
              ))}
            </Tr>
          ))}
        </Tfoot>
      </Table>
    </Card>
  );
}
