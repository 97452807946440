import { Box, Stack, Text } from "@chakra-ui/react";
import CopyComponent from "./CopyComponent";

export const LabeledTextField = ({
  title,
  showCopyButton = false,
  copyValue,
  value: component,
  style,
  ...attributes
}) => {
  return (
    <Stack direction="column" {...attributes}>
      {showCopyButton && copyValue ? (
        <CopyComponent
          fontSize="sm"
          fontWeight="bold"
          isTruncated
          backgroundColor="whiteAlpha.300"
          paddingLeft=""
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          component={title || ""}
          value={copyValue}
        />
      ) : (
        <Text
          fontSize="sm"
          fontWeight="bold"
          isTruncated
          backgroundColor="whiteAlpha.300"
          paddingLeft=""
        >
          {title || ""}
        </Text>
      )}
      <Box fontSize="sm" style={style}>
        {component}
      </Box>
    </Stack>
  );
};
