import {
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  Text,
  Select,
  Center,
  Divider,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import React from "react";

import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLastPage,
  MdFirstPage,
} from "react-icons/md";

export const PAGE_SIZES = {
  sm: 5,
  md: 10,
  lg: 25,
  xl: 50,
  xxl: 100,
  xxxl: 150,
};

export default function Pagination({ setPage, data, setPageSize }) {
  const {
    page,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    totalPages,
    totalDocs,
    limit,
  } = data.data;

  const [inputValue, setInputValue] = React.useState(page);

  const debouncedChangeHandler = React.useCallback(
    () => debounce((value) => setPage(value), 500),
    [setPage]
  );

  React.useEffect(() => {
    setInputValue(page);
  }, [page]);

  return (
    <HStack p={0} w="full">
      <HStack flexGrow={2}>
        <HStack>
          <IconButton
            className="border rounded p-1"
            onClick={() => setPage(1)}
            isDisabled={!hasPrevPage}
            icon={<MdFirstPage />}
            size="sm"
            bg="transparent"
            color={"#7551FF"}
          />
          <Button
            leftIcon={<MdKeyboardArrowLeft color={"#7551FF"} />}
            bg="transparent"
            color="white"
            cursor="pointer"
            onClick={() => setPage(prevPage)}
            isDisabled={!hasPrevPage}
            size="sm"
            m={"2px !important"}
            px={2}
          >
            Previous
          </Button>
        </HStack>

        <Flex
          as="span"
          direction="row"
          align="center"
          position="relative"
          gap={1}
          _after={{
            content: '""',
            position: "absolute",
            left: 0,
            bottom: 0,
            height: "2px",
            width: "100%",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.5)",
            borderRadius: "2px",
          }}
          mx="25px !important"
        >
          Showing
          <Text fontWeight="900">{(page - 1) * limit + 1 || "0"}</Text>
          {"-"}
          <Text fontWeight="900">{page * limit || "0"}</Text>
          of
          <Text fontWeight="900">{totalDocs || "0"}</Text>
        </Flex>

        <HStack>
          <Button
            rightIcon={<MdKeyboardArrowRight color={"#7551FF"} />}
            bg="transparent"
            color="white"
            cursor="pointer"
            onClick={() => setPage(nextPage)}
            isDisabled={!hasNextPage}
            size="sm"
            m={"2px !important"}
            px={2}
          >
            Next
          </Button>
          <IconButton
            bg="transparent"
            color={"#7551FF"}
            m={"0px !important"}
            size="sm"
            onClick={() => setPage(totalPages)}
            isDisabled={!hasNextPage}
            icon={<MdLastPage />}
          />
        </HStack>
      </HStack>

      <HStack flexGrow={1} justify={"right"} ml="auto !important">
        <Text>Page</Text>
        <Input
          type="number"
          size="xs"
          textAlign={"center"}
          value={inputValue}
          color={"white"}
          borderColor={"#7551FF"}
          opacity={0.7}
          fontWeight={900}
          onChange={(e) => {
            setInputValue(e.target.value);
            debouncedChangeHandler(e.target.value);
          }}
          width={20}
        />

        <Flex alignItems={"center"} gap={1}>
          of
          <Text fontWeight={900}>{totalPages}</Text>
        </Flex>

        <Center h={8} mx={"12px !important"}>
          <Divider orientation="vertical" />
        </Center>

        <Text ml="0px !important">Results per page</Text>
        <Select
          size="xs"
          w={20}
          borderColor={"#7551FF"}
          textAlign={"center"}
          value={limit}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setPage(1);
          }}
        >
          {Object.values(PAGE_SIZES).map((page_size) => (
            <option key={`option-${page_size}`} value={page_size}>
              {page_size}
            </option>
          ))}
        </Select>
      </HStack>
    </HStack>
  );
}
