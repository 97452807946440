import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

function PrivateRoute({ children }) {
  const { auth } = useAuth();
  if (!auth.user) {
    // User not authenticated, redirect to sign-in
    return <Navigate to="/auth/sign-in" replace />;
  }
  // User is authenticated, render the children component
  return children;
}

export default PrivateRoute;
