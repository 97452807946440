import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Client } from "api/axios";
import Notes from "components/Notes";
import StatusView from "./StatusView";

const ExpandableRow = ({ rowData, payoutId, payoutStatus }) => {
  const addNoteForPayout = (message) => {
    const client = Client(true);
    return client.post(`/api/payouts/${payoutId}/notes`, { message });
  };

  const deleteNoteForPayout = (noteId) => {
    const client = Client(true);
    client.delete(`/api/notes/${noteId}`).then(refetchNotes);
  };
  const [notesFilters, setNotesFilters] = useState({ filter: "" });

  const [notesPagination, setNotesPagination] = useState({
    limit: 10,
    page: 1,
  });
  const getNotesForPayout = async () => {
    const client = Client(true);
    const queryParameters = new URLSearchParams({
      page: notesPagination.page,
      limit: notesPagination.limit,
    });
    if (notesFilters && notesFilters.filter) {
      queryParameters.append("filter", notesFilters.filter);
    }
    if (
      notesFilters?.filterActionsSelected &&
      notesFilters?.filterActionsSelected.length
    ) {
      notesFilters.filterActionsSelected.forEach((element) => {
        queryParameters.append("filterAction", element);
      });
    }

    const result = await client.get(
      `/api/payouts/${payoutId}/notes?${queryParameters.toString()}`
    );
    return result.data;
  };

  const {
    data: notesData,
    isLoading: notesLoading,
    refetch: refetchNotes,
  } = useQuery({
    queryKey: [
      "payouts",
      payoutId,
      "notes",
      notesFilters,
      notesPagination.page,
      notesPagination.limit,
    ],
    queryFn: getNotesForPayout,
  });

  useEffect(() => {
    if (!notesData || !notesData.docs) return;
    const { docs, ...rest } = notesData;

    setNotesPagination({ ...rest });
  }, [notesData]);

  const { mutate: addNote } = useMutation({
    mutationFn: addNoteForPayout,
    onSuccess: refetchNotes,
  });

  const { mutate: deleteNote } = useMutation({
    mutationFn: deleteNoteForPayout,
    onSuccess: refetchNotes,
  });

  useEffect(() => {
    if (payoutId) {
      refetchNotes();
    }
  }, [payoutId, refetchNotes]);

  return rowData ? (
    <>
      <StatusView payoutStatus={payoutStatus} data={rowData} />
      <Notes
        notes={notesData?.docs || []}
        loading={notesLoading}
        addNote={addNote}
        deleteNote={deleteNote}
        marginTop={2}
        w="full"
        onFilterChange={setNotesFilters}
        pagination={notesPagination}
        onPaginationChange={({ page, limit }) => {
          setNotesPagination({ ...notesPagination, page, limit });
        }}
        filterActions={[
          {
            title: "System Emails",
            value: "emails",
            selected: true,
          },
          {
            title: "Client activity",
            value: "client",
          },
          {
            title: "Trade Account activity",
            value: "account",
          },
          {
            title: "System operations",
            value: "system",
          },
        ]}
      />
    </>
  ) : null;
};

export default ExpandableRow;
