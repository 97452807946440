import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "assets/css/App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthPage from "views/auth/signIn";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import PrivateRoute from "./contexts/PrivateRoute";
import { ToastContainer } from "react-toastify";
import FormLayout from "views/form";
import { AuthProvider } from "./contexts/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FeatureSwitchProvider } from "contexts/FeatureSwitchProvider";
import { SettingsContextProvider } from "./contexts/SettingsProvider";
import { UserRoutesProvider } from "contexts/UserRoutesProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // do not refetch on window focus by default
      refetchOnWindowFocus: false,
    },
  },
});
const RedirectOldHashUrl = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      // Extract path and query from the hash
      const match = /#\/?([^?]*)(\?.*)?$/.exec(hash);
      const path = match[1] || "";
      const search = match[2] || "";
      const newUrl = `/${path}${search}`;
      // pg - in theory it should work with 'navigate' but it doesn't
      window.location.href = newUrl;
    }
  }, []);

  return null;
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <QueryClientProvider client={queryClient}>
    <FeatureSwitchProvider>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          <SettingsContextProvider>
            <BrowserRouter>
              <AuthProvider>
                <UserRoutesProvider>
                  <RedirectOldHashUrl />
                  <Routes>
                    <Route path={`/form`} element={<FormLayout />} />
                    <Route path={`/auth/sign-in`} element={<AuthPage />} />
                    <Route
                      path={`/`}
                      element={
                        <PrivateRoute>
                          <AdminLayout />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={`/admin/*`}
                      element={
                        <PrivateRoute>
                          <AdminLayout />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      element={<Navigate from="/" to="/auth/sign-in" replace />}
                    />
                  </Routes>
                </UserRoutesProvider>
              </AuthProvider>
              <ToastContainer stacked />
            </BrowserRouter>
          </SettingsContextProvider>
        </React.StrictMode>
      </ChakraProvider>
    </FeatureSwitchProvider>
  </QueryClientProvider>
);
