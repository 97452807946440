// Chakra imports
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

import { useQuery } from "@tanstack/react-query";
import { Client } from "api/axios";
import debounce from "lodash.debounce";
import { useEffect, useState } from "react";
import Pagination, { PAGE_SIZES } from "components/pagination/pagination";
import TradeAccountsTable from "./components/TradeAccountsTable";

export default function TradeAccounts() {
  const [page, setPage] = useState(0);
  const [forceLoad, forceReload] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZES["md"]);
  const [statusFilter, setStatusFilter] = useState("any");
  const [accountTypeFilter, setAccountTypeFilter] = useState("any");
  const [programFilter, setProgramFilter] = useState([]);
  const [platformFilter, setPlatformFilter] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const debouncedSetFilter = debounce(setTextFilter, 500);

  // const debouncedSetStatusFilter = debounce(setStatusFilter, 500);
  const debouncedAccountTypeFilter = debounce(setAccountTypeFilter, 500);
  const fetchAccounts = async (page, pageSize, filters) => {
    const data = Client(true).get(
      `/api/trade-accounts?limit=${pageSize}&page=${page}&populate=client&populate=ftmsBreaches${filters}`
    );
    return await data;
  };

  const { data, loading } = useQuery({
    queryKey: [
      `/api/trade-accounts/`,
      page,
      pageSize,
      statusFilter,
      accountTypeFilter,
      programFilter,
      platformFilter,
      textFilter,
      forceLoad,
    ],
    queryFn: async () => {
      let filters = "";
      if (accountTypeFilter === "live") {
        filters += "&live=true";
      }
      if (accountTypeFilter === "evaluation") {
        filters += "&evaluation=true";
      }
      if (statusFilter === "breached") {
        filters += "&stats.breached=true";
      }
      if (statusFilter === "passed") {
        filters += "&stats.passed=true";
      }
      if (statusFilter === "active") {
        filters += "&stats.breached=false&stats.passed=false";
      }
      if (programFilter) {
        filters += `&program=${programFilter}`;
      }
      if (textFilter) {
        filters += `&query=${textFilter}`;
      }
      if (platformFilter) {
        filters += `&platform=${platformFilter}`;
      }

      return await fetchAccounts(page, pageSize, filters);
    },
    keepPreviousData: true,
    initialData: { data: { docs: [] } },
  });
  useEffect(() => {
    if (data && page > data.data.totalPages) {
      setPage(data.data.totalPages);
    }
  }, [data, page]);

  return (
    <Box>
      <Flex mb={4} wrap="wrap" gap={3}>
        <Stack spacing={[2, 5]} direction={["column", "row"]}>
          <FormControl mr={10}>
            <HStack spacing="10px" alignItems="center">
              <FormLabel width={130} margin={0}>
                Status
              </FormLabel>

              <RadioGroup
                value={statusFilter}
                onChange={(value) => {
                  setStatusFilter(value);
                }}
              >
                <Stack direction="row" gap={2}>
                  <Radio key="any" value="any" variant="Button">
                    Any
                  </Radio>
                  <Radio key="breached" value="breached" variant="Button">
                    Breached
                  </Radio>
                  <Radio key="passed" value="passed" variant="Button">
                    Passed
                  </Radio>
                  <Radio key="active" value="active" variant="Button">
                    Active
                  </Radio>
                </Stack>
              </RadioGroup>
            </HStack>
          </FormControl>
          <FormControl>
            <HStack spacing="10px" alignItems="center">
              <FormLabel margin={0} width={120}>
                Account Type
              </FormLabel>
              <RadioGroup
                name="status"
                defaultValue={accountTypeFilter}
                onChange={(value) => {
                  debouncedAccountTypeFilter(value);
                }}
              >
                <Stack direction="row" gap={2}>
                  <Radio key="any" value="any" variant="Button">
                    Any
                  </Radio>
                  <Radio key="evaluation" value="evaluation" variant="Button">
                    Evaluation
                  </Radio>
                  <Radio key="live" value="live" variant="Button">
                    Live
                  </Radio>
                </Stack>
              </RadioGroup>
            </HStack>
          </FormControl>
        </Stack>
        <FormControl width={"100%"}>
          <Stack spacing={[2, 5]} direction={["column", "row"]}>
            <FormLabel width={120} margin={0}>
              Program Type
            </FormLabel>
            <CheckboxGroup
              colorScheme="green"
              defaultValue={programFilter}
              onChange={(e) => {
                setProgramFilter(e);
              }}
            >
              <Stack spacing={[2, 5]} direction={["column", "row"]}>
                <Checkbox value="experienced">Experienced</Checkbox>
                <Checkbox value="advanced">Advanced</Checkbox>
                <Checkbox value="master">Master</Checkbox>
                <Checkbox value="premium">Premium</Checkbox>
              </Stack>
            </CheckboxGroup>
          </Stack>
        </FormControl>
        <Flex
          justifyContent={"space-between"}
          spacing={[2, 5]}
          w={"100%"}
          direction={["column", "row"]}
        >
          <FormControl width={"100%"}>
            <Stack spacing={[2, 5]} direction={["column", "row"]}>
              <FormLabel width={120} margin={0}>
                Platform Type
              </FormLabel>
              <CheckboxGroup
                colorScheme="green"
                defaultValue={platformFilter}
                onChange={(e) => {
                  setPlatformFilter(e);
                }}
              >
                <Stack spacing={[2, 5]} direction={["column", "row"]}>
                  <Checkbox value="mt4">MT4</Checkbox>
                  <Checkbox value="mt5">MT5</Checkbox>
                </Stack>
              </CheckboxGroup>
            </Stack>
          </FormControl>
          <FormControl width={"100%"}>
            <Input
              placeholder="Enter TradeAccount ID or email"
              onChange={(e) => {
                debouncedSetFilter(e.target.value);
              }}
            />
          </FormControl>
        </Flex>
      </Flex>
      {data ? (
        <TradeAccountsTable
          tableData={data.data}
          loading={loading}
          onDataLoad={() => {
            forceReload(forceLoad + 1);
          }}
        />
      ) : (
        "Loading..."
      )}

      <div className="h-2" />
      <Pagination setPage={setPage} data={data} setPageSize={setPageSize} />
    </Box>
  );
}
