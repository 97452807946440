import React from "react";
import { HStack, Text } from "@chakra-ui/react";

const DateTime = ({ isoString }) => {

  if (!isoString) {
    return <Text>N/A</Text>;
  }

  const date = new Date(isoString);
  const dateString = date.toLocaleDateString();
  const timeString = date.toLocaleTimeString();

  return (
    <HStack wrap='wrap'>
      <Text>{dateString}</Text>
      <Text>{timeString}</Text>
    </HStack>
  );
};

export default DateTime;
