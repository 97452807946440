import { Box, HStack, Text, VStack } from "@chakra-ui/react";

const BasicMetricView = ({ data }) => (
  <VStack spacing={0}>
    {data.map((item, index) => {
      return item.value === 0 || item.value ? (
        <HStack key={index} spacing={0} gap={0} w="full">
          <Text
            backgroundColor={"#0B1437 !important"}
            border={"3px solid !important"}
            borderColor={"#0A1129 !important"}
            borderBottom={"0px !important"}
            wordBreak="break-all"
            isTruncated
            py={0.5}
            px={2}
            w="35%"
            minW="35%"
            h="full"
            fontWeight={600}
          >
            {item.label}
          </Text>

          <Box
            borderTop={"3px solid !important"}
            borderColor={"#0A1129 !important"}
            borderBottom={"0px !important"}
            py={0.5}
            px={2}
            w="full"
            h="full"
            isTruncated
          >
            {item.value}
          </Box>
        </HStack>
      ) : null;
    })}
  </VStack>
);

export default BasicMetricView;
