import {
  Button,
  Text,
  Heading,
  HStack,
  Input,
  InputGroup,
  Box,
  InputLeftAddon,
  VStack,
} from "@chakra-ui/react";
import validator from "validator";

import { MdOutlineEmail, MdCardTravel, MdAttachMoney } from "react-icons/md";
import { useState, useEffect } from "react";

const USDollar = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default function StartForm({ nextStep, formData }) {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (
      validator.isEmpty(formData.name) ||
      validator.isEmpty(formData.email) ||
      formData.amount <= 0.01
    ) {
      setError("InvalidPayoutInformation");
    } else {
      setError(null);
    }
  }, [formData, setError]);

  const toNext = () => {
    nextStep && nextStep();
  };

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md">Payout Information Form</Heading>
      <InputGroup>
        <InputLeftAddon>
          <MdCardTravel />
        </InputLeftAddon>
        <Input
          disabled
          placeholder={"Payee's Legal Name"}
          value={formData.name}
        />
      </InputGroup>

      <InputGroup>
        <InputLeftAddon>
          <MdOutlineEmail />
        </InputLeftAddon>
        <Input
          disabled
          placeholder={"Payee's Email Address"}
          value={formData.email}
        />
      </InputGroup>

      <InputGroup>
        <InputLeftAddon>
          <MdAttachMoney />
        </InputLeftAddon>
        <Input
          disabled
          placeholder={"0.00"}
          value={USDollar.format(formData.amount)}
        />
      </InputGroup>

      <HStack justify="end">
        <Button colorScheme={"green"} onClick={toNext} disabled={!!error}>
          Next
        </Button>
      </HStack>
      <Box
        display={error === "InvalidPayoutInformation" ? "block" : "none"}
        color="red.500"
      >
        <Text>Invalid Payout Information</Text>
        <Text>Please contact support info@fundedtradingplus.com</Text>
      </Box>
    </VStack>
  );
}
