import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Client } from "api/axios";
import { toast } from "react-toastify";
import { ALERT_MESSAGE } from "variables/message";
import TechnicalSettingsForm from "./components/TechnicalSettingsForm";

export default function TechnicalSettings() {
  const saveSettingsFn = async (config) => {
    const client = Client(true);
    await client.patch(`/api/settings/secrets`, config);
  };
  const getSettingsFn = async () => {
    const client = Client(true);
    const { data } = await client.get(`/api/settings/secrets`);
    return data;
  };

  const { status, data } = useQuery({
    queryKey: ["secret-settings"],
    queryFn: getSettingsFn,
  });

  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (status === "success" && data) {
      setConfig(data);
    }
  }, [status, data]);

  const queryClient = useQueryClient();
  const saveSettings = useMutation({
    mutationFn: saveSettingsFn,
    onSuccess: async () => {
      toast.success(ALERT_MESSAGE.PASS_ACTION_SUCCESS);

      await queryClient.invalidateQueries({ queryKey: ["secret-settings"] });
    },
  });

  const saveConfig = async (config) => {
    saveSettings.mutate(config);
  };

  return (
    <Box>
      {/* Main Fields */}
      {config ? (
        <TechnicalSettingsForm saveConfig={saveConfig} config={config} />
      ) : null}
    </Box>
  );
}
