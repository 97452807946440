import React from "react";
import BasicMetricView from "./BasicMetricView";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import DateTime from "components/DateTime";

const StatusViewUnused = ({ data: row }) => {
  return (
    <>
      <GroupingContainer flex={1} title={"Overview"}>
        <BasicMetricView
          data={[
            {
              label: "Date Sent",
              value: <DateTime isoString={row?.formSentAt} />,
            },
          ]}
        />
      </GroupingContainer>
    </>
  );
};

export default StatusViewUnused;
