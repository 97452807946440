import { Flex, Text, Badge, HStack } from "@chakra-ui/react";

// Custom components
import { formatMoney } from "variables/utils";
import { LabeledTextField } from "components/LabeledTextField";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";

function Profile({ client, w }) {
  return (
    <GroupingContainer
      w={w}
      title={"Client Information"}
      fontSize="sm"
      style={{ minHeight: 210 }}
    >
      <LabeledTextField
        title="Name"
        showCopyButton={true}
        copyValue={client.name}
        value={<Text>{client.name}</Text>}
      />
      <LabeledTextField
        title="# ID"
        showCopyButton={true}
        copyValue={client.id}
        value={<Text>{client.id}</Text>}
      />
      <HStack alignItems={"flex-start"}>
        <LabeledTextField
          title="Accounts"
          value={
            <Flex gap={0.5} wrap={"wrap"}>
              <Badge size="small" colorScheme="green">
                {client.accountStats?.passed} passed
              </Badge>
              <Badge size="small" colorScheme="yellow">
                {client.accountStats?.active} active
              </Badge>
              <Badge size="small" colorScheme="red">
                {client.accountStats?.breached} breached
              </Badge>
            </Flex>
          }
        />
        <LabeledTextField
          flexGrow={1}
          title="Lifetime Value"
          value={
            <Flex gap={0.5} wrap={"wrap"}>
              <Badge size="small" colorScheme="">
                {formatMoney(client.lifetimeValue)}
              </Badge>
            </Flex>
          }
        />
      </HStack>
    </GroupingContainer>
  );
}

export default Profile;
