import React from "react";
import { Button } from "@chakra-ui/react";

const ActionButton = ({ children, textStyles, ...rest }) => {
  return (
    <Button style={{ justifyContent: "space-between" }} {...rest}>
      <span style={{ textAlign: "left", flexGrow: 1 }} {...textStyles}>
        {children}
      </span>
    </Button>
  );
};

export default ActionButton;
