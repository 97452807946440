import { Text } from "@chakra-ui/react";

export const addressToString = ({
  city,
  code,
  country,
  region,
  street,
  suite,
}) => {
  let address = "";
  address = suite ? suite : "";

  if (suite && street) {
    address += ` ${street}`;
  } else if (street) {
    address += `${street}`;
  }
  if (city) {
    address += `\n${city}`;
  }
  if (region) {
    address += `, ${region}`;
  }
  if (code) {
    address += `\n${code}`;
  }
  if (country) {
    address += ` ${country}`;
  }

  return address;
};

export default function AddressFormatted({
  city,
  code,
  country,
  region,
  street,
  suite,
}) {
  // handle the address formatting, make sure to handle the case where the address parts are null/undefined/empty
  return (
    <Text noOfLines={3}>
      {suite ? <>{suite}</> : null}
      {street ? <>{street}</> : null}
      {city ? (
        <>
          <br />
          {city}
        </>
      ) : null}
      {region ? <>, {region}</> : null}
      {
        <>
          <br />
          {code ? code : ""}
        </>
      }
      &nbsp;{country || ""}
    </Text>
  );
}
