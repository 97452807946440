// Chakra imports
import UserTable from "views/admin/dataTables/components/UserTable";
// Custom components
import { useQuery } from "@tanstack/react-query";
import { columnsDataUser } from "views/admin/dataTables/variables/columnsData";
import { Client } from "../../../../api/axios";

export default function UserManage() {
  const fetchUsers = async () => {
    const client = Client(true);
    const response = await client.get(`/api/users?limit=100`);
    return response.data.docs;
  };

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
  });

  return <UserTable columnsData={columnsDataUser} tableData={users || []} />;
}
