import { useSettings } from "contexts/SettingsProvider";
import { useCallback } from "react";

const { Link, Button } = require("@chakra-ui/react");
const { MdOpenInNew } = require("react-icons/md");

const FpfxAccountLink = ({ accountId }) => {
  const settings = useSettings();

  const getFpfxAccountLink = useCallback((accountId) => {
    const fpfxUrl = settings?.publicSettings?.fpfx?.portalUrl;

    if (fpfxUrl && accountId) {
      return `${fpfxUrl}/accountdetail/${accountId}`;
    }
    return undefined;
  }, [settings]);

  if (!accountId) {
    return null;
  }

  if (!settings?.publicSettings?.fpfx?.portalUrl) {
    return null;
  }

  return (
    <Button size='xs' variant='outlined' aria-label={`Open Account ${accountId} in FPFX HUB`}>
      <Link
        href={getFpfxAccountLink(accountId)}
        isExternal
      >
        FPFX Hub
        <MdOpenInNew style={{ display: 'inline' }} />
      </Link>
    </Button>
  );
}

export default FpfxAccountLink;