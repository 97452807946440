// Chakra imports
import { Box, HStack, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";

function AuthIllustration(props) {
  const { children } = props;
  // Chakra color mode
  return (
    <HStack h="100vh" w="100hw" mx="auto" justifyContent="center">
      <VStack w={{ base: "100%", md: "50%" }}>
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          h="100px"
          bgRepeat="no-repeat"
          bgSize="contain"
          bgPosition="50%"
          bgImage={props.image}
          mb="20px"
        ></Box>
        {children}
        <Footer />
      </VStack>
      <Box
        display={{ base: "none", md: "flex" }}
        w={{ base: "100%", md: "50%" }}
        h="100%"
        right="0px"
        justify="center"
        alignItems="center"
        borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
        bg="rgba(0,0,0,0.3)"
      >
        <Box
          w="50%"
          h="50%"
          bgImage={props.image}
          bgSize="contain"
          bgRepeat="no-repeat"
          bgPosition="50%"
          position="absolute"
          borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
        />
      </Box>
    </HStack>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
