import { GroupingContainer } from "./groupingContainer/GroupingContainer";
import {
  IconButton,
  Grid,
  GridItem,
  Text,
  Box,
  Badge,
  HStack,
  Button,
} from "@chakra-ui/react";
import {
  MdDelete,
  MdSend,
  MdKeyboardArrowRight,
  MdOutlineChevronLeft,
} from "react-icons/md";
import DateTime from "./DateTime";
import { useForm } from "react-hook-form";
import { FormInput } from "components/FormInput";
import { useEffect, useState } from "react";
import "./notes.css";
import CheckboxMenu from "./CheckboxMenu";
import { camelCaseToHumanReadable } from "variables/utils";
import autoConvertLinks from "utils/autoConvertLinks";

export default function Notes({
  notes,
  loading,
  addNote,
  deleteNote,
  showSearch = true,
  filterActions = [],
  onFilterChange,
  onPaginationChange,
  pagination,
  ...props
}) {
  const { handleSubmit, reset, control } = useForm();
  const { control: filterForm } = useForm();

  const filterOptions = true;
  const [filter, setFilter] = useState("");

  const [filterActionsSelected, setFilterActionsSelected] = useState(
    filterActions.length
      ? filterActions.filter((a) => a.selected).map((a) => a.value)
      : []
  );

  useEffect(() => {
    const filterChange = {};
    if (filter) {
      filterChange.filter = filter;
    }
    if (filterActionsSelected.length) {
      filterChange.filterActionsSelected = filterActionsSelected;
    }
    onFilterChange && onFilterChange(filterChange);
  }, [onFilterChange, filterActionsSelected, filter]);

  const createNote = (data) => {
    addNote(data.note);
    reset({ note: "" });
  };

  return (
    <GroupingContainer title="Notes" {...props} containerPadding={0} gap={0}>
      {filterOptions && (
        <GridItem
          colSpan={3}
          style={{
            zIndex: 1,
          }}
        >
          <Box
            borderRadius={2}
            backgroundColor={"whiteAlpha.300"}
            display="flex"
            alignItems={"center"}
            gap={2}
            px={2}
          >
            <CheckboxMenu
              items={filterActions}
              selectedValues={filterActionsSelected}
              size={"xs"}
              onChange={setFilterActionsSelected}
            >
              Notes Visibility
            </CheckboxMenu>

            {showSearch && (
              <FormInput
                m={0}
                p={1}
                flexGrow={1}
                w="auto"
                control={filterForm}
                size={"sm"}
                id="search"
                placeholder="Search..."
                defaultValue={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            )}
            {pagination.totalPages > 1 && (
              <>
                <Button
                  size={"xs"}
                  isDisabled={!pagination.hasPrevPage}
                  leftIcon={<MdOutlineChevronLeft />}
                  onClick={() => {
                    onPaginationChange({
                      ...pagination,
                      page: pagination.prevPage,
                    });
                  }}
                >
                  Prev
                </Button>
                <Text>
                  Page {pagination.page}/{pagination.totalPages}
                </Text>
                <Button
                  rightIcon={<MdKeyboardArrowRight color={"#7551FF"} />}
                  size={"xs"}
                  bg="transparent"
                  color="white"
                  cursor="pointer"
                  onClick={() =>
                    onPaginationChange({
                      ...pagination,
                      page: pagination.nextPage,
                    })
                  }
                  isDisabled={!pagination.hasNextPage}
                  m={"2px !important"}
                  px={2}
                >
                  Next
                </Button>
              </>
            )}
          </Box>
        </GridItem>
      )}
      <Grid
        templateColumns="auto 4fr auto"
        rowGap={0}
        className="notes-container"
        columnGap={0}
        minH={loading ? "15rem" : "15rem"}
        maxH="30vh"
        overflowY="auto"
        flex="1"
        p={1}
        alignContent={"start"}
      >
        {notes &&
          notes.map((note) => {
            return [
              <GridItem key={"details-" + note.id}>
                <Text p={0}>{note.references?.user?.name || "n/a"}</Text>
                <DateTime p={0} isoString={note.updatedAt} />
              </GridItem>,
              <GridItem key={"message-" + note.id}>
                <HStack h="100%">
                  <Badge textAlign={"center"} minW={"3rem"}>
                    {camelCaseToHumanReadable(note.actionType)}
                  </Badge>
                  <Text
                    whiteSpace={"normal"}
                    dangerouslySetInnerHTML={{
                      __html: autoConvertLinks(note.message),
                    }}
                  ></Text>
                </HStack>
              </GridItem>,
              <GridItem key={"actions-" + note.id}>
                {note.actionType === "message" ? (
                  <IconButton
                    size={"sm"}
                    onClick={() => deleteNote(note.id)}
                    icon={<MdDelete />}
                  ></IconButton>
                ) : null}
              </GridItem>,
            ];
          })}
      </Grid>

      <form onSubmit={handleSubmit(createNote)} autoComplete="off">
        <Box display={"flex"}>
          <FormInput
            id="note"
            flexGrow={1}
            placeholder="Add a note..."
            rules={{ required: "This is required." }}
            control={control}
            m={0}
          />
          <IconButton
            type="submit"
            icon={<MdSend />}
            aria-label="Send note"
          ></IconButton>
        </Box>
      </form>
    </GroupingContainer>
  );
}
